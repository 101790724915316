import React from "react";
import axios from "axios";

export default axios.create({
  //baseURL: `http://localhost:53999/`,
  baseURL: `https://yased-api.yased.org.tr/`,
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "same-origin",
});
