import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Api from "../../common/api/api";

class DigitalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      notes: [],
    };
  }

  componentDidMount() {
    Api.get("contents/getdetailwithnote?slug=" + this.props.id).then((res) => {
      this.updateData(res.data);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    Api.get("contents/getdetailwithnote?slug=" + this.props.id).then((res) => {
      this.updateData(res.data);
    });
  }

  updateData = (values) => {
    this.setState({ content: values.data });
    this.setState({ notes: values.notes });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  Greeting(props) {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: props.Description_EN,
          }}
        ></div>
      );
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: props.Description,
          }}
        ></div>
      );
    }
  }

  getTitle(title) {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      return <h1 className="about-title">{title.Title_EN}</h1>;
    } else {
      <h1 className="about-title">{title.Title}</h1>;
    }
  }

  DocTitle() {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      return <span className="workG-download-pdf-text">Membership Terms</span>;
    } else {
      return <span className="workG-download-pdf-text">Dernek Tüzüğü</span>;
    }
  }

  getDocLang() {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      return (
        <a
          target="_blank"
          href={
            "http://yased-api.yased.org.tr/Uploads/Media/YASED_TÜZÜK_ENG.pdf"
          }
          className="general-btn pull-right"
        >
          <i className="icon-base icon-pdf"></i>
          {this.DocTitle()}
        </a>
      );
    } else {
      return (
        <a
          target="_blank"
          href={
            "https://yased-api.yased.org.tr/Uploads/Note/1d204cfe-a451-4669-b06e-de63f0545067.pdf"
          }
          className="general-btn pull-right"
        >
          <i className="icon-base icon-pdf"></i>
          {this.DocTitle()}
        </a>
      );
    }
  }

  render() {
    const notes = this.state.notes[0];
    const page = this.props.id;
    const contentData = this.state.content;
    let comp;
    if (contentData.Type != 2) {
      comp = (
        <div className={"about-container abouts " + page}>
          <div className="about-image">
            <img src={"https://yased-api.yased.org.tr/" + contentData.Image} />
          </div>
          <div className="about-text-container">
            <div className="workG-download">{this.getDocLang()}</div>
            <Scrollbars autoHide={false} autoHeight autoHeightMax={`39vh`}>
              <div className="about-text">{this.Greeting(contentData)}</div>
            </Scrollbars>
          </div>
        </div>
      );
    } else {
      comp = (
        <div className="about-container">
          {this.getTitle(contentData)}
          <div className="about-image-box about-table-box">
            <Scrollbars autoHide={false} autoHeight autoHeightMax={`75vh`}>
              {this.Greeting(contentData)}
            </Scrollbars>
          </div>
        </div>
      );
    }

    return <div className="abbt">{comp}</div>;
  }
}

export default DigitalDetail;
