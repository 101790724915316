import React from "react";
import { Router } from "@reach/router";
import ChainMenu from "../menus/chainMenu";
import Api from "../../common/api/api";
import ChainDetail from "./chainDetail";

class Chain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenu: [],
      submenuAlt: [],
    };
    const menu = [];
  }
  componentDidMount() {
    this.getMenu();
  }
  getMenu = () => {
    let submenuAltArr = [];
    Api.get("/contents/getsubmenu/66").then((res) => {
      this.setState({ submenu: res.data, submenuAlt: submenuAltArr });
    });
  };

  render() {
    const data = this.state.submenu;
    return (
      <div>
        <Router>
          <ChainDetail path={"/:id"} />
        </Router>
        <ChainMenu submenu={data} />
      </div>
    );
  }
}

export default Chain;
