import React from "react";
import { Link } from "@reach/router";
import ProfileSettings from "../header/profileSettings";
import Login from "../header/login";
import Logout from "../header/logout";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const lang = localStorage.getItem("lang");
    const renderAuthButton = () => {
      let users = JSON.parse(localStorage.getItem("user")) || [];
      if (users.length != 0) {
        return (
          <div className="header-right">
            <Link to="" className="header-item active">
              <ProfileSettings />
            </Link>
            <Link to="" className="header-item active">
              <Logout />
            </Link>
          </div>
        );
      } else {
        return (
          <div className="header-right">
            <Link to="" className="header-item">
              <Login />
            </Link>
          </div>
        );
      }
    };

    const LangImage = () => {
      if (lang != "en") {
        return <i className="icon-base icon-lang-en"></i>;
      } else {
        return <i className="icon-base icon-lang-tr"></i>;
      }
    };

    const changeLang = () => {
      if (lang == "en") {
        localStorage.setItem("lang", "tr");
      } else {
        localStorage.setItem("lang", "en");
      }

      window.location.href = "/";
    };

    const goHome = () => {
      window.location.href = "/";
    };

    return (
      <header className="header">
        <div className="header-left">
          <Link to="/" className="header-item" onClick={goHome}>
            <i className="icon-base icon-home"></i>
          </Link>
          <Link to="/" className="header-item" onClick={changeLang}>
            {LangImage()}
          </Link>
          <Link to="/search" className="header-item">
            <i className="icon-base icon-search"></i>
          </Link>
        </div>
        {renderAuthButton()}
      </header>
    );
  }
}

export default Header;
