import React from "react";
import { Link } from "@reach/router";
import Api from "../../common/api/api";
import { Scrollbars } from "react-custom-scrollbars";

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent
          ? "sub-menu-link sub-menu-link-active"
          : "sub-menu-link",
      };
    }}
  />
);
class AboutMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliders: [],
    };
    const menu = [];
  }

  componentDidMount() {
    Api.get("/contents/getsubmenu/66").then((res) => {
      this.setState({ sliders: res.data.items });
    });
  }

  Greeting(props) {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      return (
        <li key={props.Id} className="sub-menu-title">
          <a href={props.Link_EN}>{props.Name_EN}</a>
        </li>
      );
    } else {
      return (
        <li key={props.Id} className="sub-menu-title">
          <a href={props.Link}>{props.Name}</a>
        </li>
      );
    }
  }

  getsubMenu(subElement, isLast) {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      if (subElement.Name_EN) {
        return (
          <li key={subElement.Id} className={`sub-menu-item ${isLast}`}>
            <NavLink to={subElement.Link_EN} className="sub-menu-link">
              {subElement.Name_EN}
            </NavLink>
          </li>
        );
      }
    } else {
      return (
        <li key={subElement.Id} className={`sub-menu-item ${isLast}`}>
          <NavLink to={subElement.Link} className="sub-menu-link">
            {subElement.Name}
          </NavLink>
        </li>
      );
    }
  }

  getElement(element) {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      return (
        <li key={element.Id} className="sub-menu-item">
          <NavLink to={element.Link_EN} className="sub-menu-link">
            {element.Name_EN}
          </NavLink>
        </li>
      );
    } else {
      return (
        <li key={element.Id} className="sub-menu-item">
          <NavLink to={element.Link} className="sub-menu-link">
            {element.Name}
          </NavLink>
        </li>
      );
    }
  }

  render() {
    const data = this.state.sliders;
    let lis = [];

    let saveList = data.map((element, i) => {
      if (element.submenu.length) {
        let subSave = [];
        subSave.push(this.Greeting(element));
        element.submenu.map((subElement, i) => {
          let isLast = element.submenu.length - 1 === i ? "last" : "";
          subSave.push(this.getsubMenu(subElement, isLast));
        });
        return subSave;
      } else {
        return this.getElement(element);
      }
    });
    return (
      <div>
        <div className="sub-menu-cover">
          <ul className="sub-menu">
            {saveList && (
              <Scrollbars autoHide={false} autoHeight autoHeightMax={`75vh`}>
                {saveList}
              </Scrollbars>
            )}
          </ul>
          <button className="sub-menu-ham">
            <i className="icon-base icon-sub-ham"></i>
          </button>
        </div>
      </div>
    );
  }
}

export default AboutMenu;
