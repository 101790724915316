import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Api from "../../common/api/api";
import Modal from "../../common/modal/modal";

class GroupContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      content: "",
      notes: [],
    };
  }

  componentDidMount() {
    Api.get("contents/getdetailwithnote?slug=" + this.props.id).then((res) => {
      this.updateData(res.data);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    Api.get("contents/getdetailwithnote?slug=" + this.props.id).then((res) => {
      this.updateData(res.data);
    });
  }
  updateData = (values) => {
    this.setState({ content: values.data ,notes: values.notes,profile: values.profile});
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  Greeting(props,profile) {
    let lang = localStorage.getItem("lang");
    if (lang == "en") {
      if(profile){
      return (
        <>
        {profile.title_en && profile.label_en ?  (
        <div className="profile">
        <img src={"https://yased-api.yased.org.tr/"+profile.image} />
            <div className="content">
              <h5>{profile.title_en}</h5>
              <p>{profile.name}</p>
              <p>{profile.label_en}</p>
            </div>
        </div>
        ):null}
        <div
          className="about-text"
          dangerouslySetInnerHTML={{
            __html: props.Description_EN,
          }}
        ></div>
        </>
      );
    }else{
      return (
        <div
          className="about-text"
          dangerouslySetInnerHTML={{
            __html: props.Description_EN,
          }}
        ></div>
      );
    }
    } else {
      if(profile){
        return (
          <>
          {profile.title && profile.label ?  (
          <div className="profile">
            <img src={"https://yased-api.yased.org.tr/"+profile.image} />
            <div className="content">
              <h5>{profile.title}</h5>
              <p>{profile.name}</p>
              <p>{profile.label}</p>
            </div>
          </div>
          ):null}
          <div
            className="about-text"
            dangerouslySetInnerHTML={{
              __html: props.Description,
            }}
          ></div>
          </>
        );
      }else{
        return (
          <div
            className="about-text"
            dangerouslySetInnerHTML={{
              __html: props.Description,
            }}
          ></div>
        );
      }
    }
  }


  render() {
    const { showModal } = this.state;

    const ShowNoteButton = () => {
      let users = JSON.parse(localStorage.getItem("user")) || [];
      if (users.length != 0) {
        let lang = localStorage.getItem("lang");
        if (lang == "en") {
          return (
            <button className="general-btn" onClick={this.toggleModal}>
              <i className="icon-base icon-pdf"></i>
              <span className="workG-download-pdf-text">
                Click Meeting Notes
              </span>
            </button>
          );
        } else {
          return (
            <button className="general-btn" onClick={this.toggleModal}>
              <i className="icon-base icon-pdf"></i>
              <span className="workG-download-pdf-text">
                Toplantı notu için tıklayın
              </span>
            </button>
          );
        }
      }
    };

 


    const getTitle = () => {
      let lang = localStorage.getItem("lang");
      if (lang == "en") {
        return <h2 className="workG-modal-title">Meeting Notes</h2>;
      } else {
        return <h2 className="workG-modal-title">Toplantı Notları</h2>;
      }
    };

    const notes = this.state.notes;
    let lang = localStorage.getItem("lang");
    const page = this.props.id;
    let saveList = notes.map((element, i) => {
      if (lang == "en") {
        if (element.File_EN) {
          return (
            <li className="workG-modal-list-item" key={i}>
              <p className="workG-modal-text">{element.Title_EN}</p>
              <a
                href={"https://yased-api.yased.org.tr" + element.File_EN}
                download
                className="workG-modal-action workG-modal-download"
              >
                <i className="icon-base icon-download-gray"></i>
                DOWNLOAD
              </a>
              <a
                href={"https://yased-api.yased.org.tr" + element.File_EN}
                target="_blank"
                className="workG-modal-action workG-modal-show"
              >
                <i className="icon-base icon-magnify-gray"></i>
                SHOW
              </a>
            </li>
          );
        } else {
          return (
            <li className="workG-modal-list-item" key={i}>
              <p className="workG-modal-text">{element.Title_EN}</p>
              <a
                href={"https://yased-api.yased.org.tr" + element.File}
                download
                className="workG-modal-action workG-modal-download"
              >
                <i className="icon-base icon-download-gray"></i>
                DOWNLOAD
              </a>
              <a
                href={"https://yased-api.yased.org.tr" + element.File}
                target="_blank"
                className="workG-modal-action workG-modal-show"
              >
                <i className="icon-base icon-magnify-gray"></i>
                SHOW
              </a>
            </li>
          );
        }
      } else {
        return (
          <li className="workG-modal-list-item" key={i}>
            <p className="workG-modal-text">{element.Title}</p>
            <a
              href={"https://yased-api.yased.org.tr" + element.File}
              download
              className="workG-modal-action workG-modal-download"
            >
              <i className="icon-base icon-download-gray"></i>
              İNDİR
            </a>
            <a
              href={"https://yased-api.yased.org.tr" + element.File}
              target="_blank"
              className="workG-modal-action workG-modal-show"
            >
              <i className="icon-base icon-magnify-gray"></i>
              GÖRÜNTÜLE
            </a>
          </li>
        );
      }
    });

    return (
      <div className={page}>
        <div className="about-container">
          <div className="about-image">
            <img
              src={"https://yased-api.yased.org.tr/" + this.state.content.Image}
            />
          </div>
          <div className="about-text-container ">
            <div className="workG-download">
              {ShowNoteButton()}

              {showModal ? (
                <Modal>
                  <div className="workG-pdf-modal">
                    <button
                      className="close-modal"
                      onClick={this.toggleModal}
                    ></button>
                    {getTitle()}
                    <ul className="workG-modal-list">{saveList}</ul>
                  </div>
                </Modal>
              ) : null}
            </div>
            <Scrollbars autoHide={false} autoHeight autoHeightMax={`39vh`}>
              
              {this.Greeting(this.state.content,this.state.profile)}
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }
}

export default GroupContent;
