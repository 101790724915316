import React from "react";
import Slider from "react-slick";

class Item extends React.Component {
 
  render() {
    const lang = localStorage.getItem('lang');
    const getItem = (item,key) => {
      if (lang == "en"){
        if(item.Title_EN != null){

          // baslik bu ise
          if(item.Id == "81"){
           item.Slug_EN = "https://www.yasedinternationalinvestmentsummit.org/";
            return (
              <div key={key}>
                  <div className="news-item" >
                    <div className="news-item-img-box">
                    <a href={item.Slug_EN} target="_blank">
                        <img
                          src={"https://yased-api.yased.org.tr/" + item.Image}
                          className="news-item-img"
                          alt=""
                        />
                        </a>
                      </div>
                      <div className="news-item-content">
                      <a href={item.Slug_EN} target="_blank">
                        <p className="news-item-title">
                          {item.Title_EN}
                        </p>
                        </a>
                        <p className="news-item-text" dangerouslySetInnerHTML={{ __html: item.Spot_EN }}>
                        </p>
                        <a href={item.Slug_EN} target="_blank">
                          <span className="news-item-more">
                          read more
                          </span>
                        </a>
                        <span className="news-item-share">
                          <i className="icon-base icon-share"></i>
                        </span>
                      </div>
                  </div>
                </div>
            )

          }else{
        return (
          <div key={key}>
              <div className="news-item" >
                <div className="news-item-img-box">
                <a href={"/yased-calisiyor/"+item.Slug_EN}>
                    <img
                      src={"https://yased-api.yased.org.tr/" + item.Image}
                      className="news-item-img"
                      alt=""
                    />
                    </a>
                  </div>
                  <div className="news-item-content">
                  <a href={"/yased-calisiyor/"+item.Slug_EN}>
                    <p className="news-item-title">
                      {item.Title_EN}
                    </p>
                    </a>
                    <p className="news-item-text" dangerouslySetInnerHTML={{ __html: item.Spot_EN }}>
                    </p>
                    <a href={"/yased-calisiyor/"+item.Slug_EN}>
                      <span className="news-item-more">
                      read more
                      </span>
                    </a>
                    <span className="news-item-share">
                      <i className="icon-base icon-share"></i>
                    </span>
                  </div>
              </div>
            </div>
        )
        }
      }
      }else{
      if(item.Id == "81"){
           item.Slug = "https://www.yasedinternationalinvestmentsummit.org/";
           return (
          <div key={key}>
              <div className="news-item" >
                <div className="news-item-img-box">
                <a href={item.Slug} target="_blank">
                    <img
                      src={"https://yased-api.yased.org.tr/" + item.Image}
                      className="news-item-img"
                      alt=""
                    />
                    </a>
                  </div>
                  <div className="news-item-content">
                  <a href={item.Slug} target="_blank">
                    <p className="news-item-title">
                      {item.Title}
                    </p>
                    </a>
                    <p className="news-item-text" dangerouslySetInnerHTML={{ __html: item.Spot }}>
                    </p>
                    <a href={item.Slug} target="_blank">
                      <span className="news-item-more">
                        devamı için tıklayınız
                      </span>
                    </a>
                    <span className="news-item-share">
                      <i className="icon-base icon-share"></i>
                    </span>
                  </div>
              </div>
            </div>
            
        )

         }else{
        return (
          <div key={key}>
              <div className="news-item" >
                <div className="news-item-img-box">
                <a href={"/yased-calisiyor/"+item.Slug}>
                    <img
                      src={"https://yased-api.yased.org.tr/" + item.Image}
                      className="news-item-img"
                      alt=""
                    />
                    </a>
                  </div>
                  <div className="news-item-content">
                  <a href={"/yased-calisiyor/"+item.Slug}>
                    <p className="news-item-title">
                      {item.Title}
                    </p>
                    </a>
                    <p className="news-item-text" dangerouslySetInnerHTML={{ __html: item.Spot }}>
                    </p>
                    <a href={"/yased-calisiyor/"+item.Slug}>
                      <span className="news-item-more">
                        devamı için tıklayınız
                      </span>
                    </a>
                    <span className="news-item-share">
                      <i className="icon-base icon-share"></i>
                    </span>
                  </div>
              </div>
            </div>
            
        )
        }
      }
    }

    const items = this.props.item;
    const settings = {
      slidesToShow: 2,
      speed: 500,
      rows: 3,
      slidesPerRow: 1,
      infinite: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          {items.map((item, key) => (
            getItem(item,key)
          ))}
        </Slider>
      </div>
    );
  }
}export default Item;
