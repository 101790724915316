import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Api from "../../common/api/api";


class Search extends React.Component {
  state = {
    name: '',
    list: [],
  };

  handleSubmit = event => {
      event.preventDefault();
      const user = {
        name: this.state.name
      }
      Api.get("search?searching="+this.state.name).then((res) => {
        this.listChange(res.data);
      });
    }

  handleChange = event =>{
      this.setState({ name: event.target.value});
    }


  listChange(data){
    this.setState({ list: data});
  }


  render() {

    const lang = localStorage.getItem('lang');

    const getTitle = (element) => {
      if (lang == "en"){
        return (
          <h1 className="about-title">Search Page</h1>
        )
      }else{
        return (
          <h1 className="about-title">Arama Sayfası</h1>
        ) 
      }
    }

    const getForm = (element) => {
      if (lang == "en"){
        return (
          <form onSubmit = { this.handleSubmit }>
              <input type="text" className="text" onChange={this.handleChange} placeholder="Add your keyword" required/>
              <button type= "submit" className="button"> Search </button>
          </form>
        )
      }else{
        return (
          <form onSubmit = { this.handleSubmit }>
              <input type="text" className="text" onChange={this.handleChange} placeholder="Aramak istediğiniz kelimeyi yazınız" required/>
              <button type= "submit" className="button"> Ara </button>
          </form>
        ) 
      }
    }


    const getContent = (element) => {
      if (lang == "en"){
        return (
          <ul className="search-list">
          {element.news ? (
            element.news.Title_EN ? (
            element.news.map(news=><li key={news.Id}><p><a href={"/news/"+news.Slug_EN}>{news.Title_EN}</a></p></li>)
            ): element.news.map(news=><li key={news.Id}><p><a href={"/news/"+news.Slug_EN}>{news.Slug_EN}</a></p></li>)
          ):null}
           {element.contents ? (
             element.contents.Title_EN ? (
                element.contents.map(contents=><li key={contents.Id}><p><a href={"/about-us/"+contents.Slug_EN}>{contents.Title_EN}</a></p></li>)
                ):element.contents.map(contents=><li key={contents.Id}><p><a href={"/about-us/"+contents.Slug_EN}>{contents.Slug_EN}</a></p></li>)
            ):null}
           {element.insights ? (
             element.insights.Title_EN ? (
            element.insights.map(insights=><li key={insights.Id}><p><a href={"/news/"+insights.Slug_EN}>{insights.Title_EN}</a></p></li>)
            ):null
          ):null}
           {element.member ? (
            element.member.map(member=><li key={member.Id}><p><a href="/about-us/members/">{member.Title_EN}</a></p></li>)
          ):null}
        </ul>
        )
      }else{
        return (
          <ul className="search-list">
            {element.news ? (
              element.news.map(news=><li key={news.Id}><p><a href={"/haberler/"+news.Slug}>{news.Title}</a></p></li>)
            ):null}
            {element.contents ? (
              element.contents.map(contents=><li key={contents.Id}><p><a href={"/hakkimizda/"+contents.Slug}>{contents.Title}</a></p></li>)
            ):null}
            {element.insights ? (
              element.insights.map(insights=><li key={insights.Id}><p><a href={"/haberler/"+insights.Slug}>{insights.Title}</a></p></li>)
            ):null}
            {element.member ? (
              element.member.map(member=><li key={member.Id}><p><a href="/hakkimizda/uyeler/">{member.Title}</a></p></li>)
            ):null}
          </ul>
        ) 
      }
    }

    return (
        <div className="about-container">
          {getTitle()}
          <div className="about-image-box about-table-box">
            <Scrollbars autoHide={false} autoHeight autoHeightMax={`75vh`}>
                <div className="search-page">
                    {getForm()}
                   {getContent(this.state.list)}

                </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Search;
