import React from "react";
import { Router } from "@reach/router";
import HumanCapitalMenu from "../menus/humanCapitalMenu";
import Api from "../../common/api/api";
import HumanCapitalDetail from "./humanCapitalDetail";

class HumanCapital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenu: [],
      submenuAlt: [],
    };
    const menu = [];
  }
  componentDidMount() {
    this.getMenu();
  }
  getMenu = () => {
    let submenuAltArr = [];
    Api.get("/contents/getsubmenu/1098").then((res) => {
      this.setState({ submenu: res.data, submenuAlt: submenuAltArr });
    });
  };

  render() {
    const data = this.state.submenu;
    return (
      <div>
        <Router>
          <HumanCapitalDetail path={"/:id"} />
        </Router>
        <HumanCapitalMenu submenu={data} />
      </div>
    );
  }
}

export default HumanCapital;
