import React from "react";
import Slider from "react-slick";

class NewsItem extends React.Component {
  
  render() {
    const data = this.props.item;
    const settings = {
        slidesToShow: 3,
        speed: 500,
        rows: 5,
        slidesPerRow: 1,
        infinite: false,
        responsive: [
          {
            breakpoint: 1605,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              rows: 4,
            },
          },
          {
            breakpoint: 1380,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              rows: 3,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 4,
              rows: 4,
            },
          },
          {
            breakpoint: 570,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 4,
            },
          },
        ],
      };
    return (
        <Slider {...settings}>
        {data.map((item, key) => (
          <div key={key}>
            <div className="member-item" key={key}>
              <div className="member-item-img-box">
                <a href={item.Slug} target="_blank">
                  <img
                    src={"https://yased-api.yased.org.tr" + item.Image}
                    className="member-item-img"
                    alt=""
                  />
                </a>
              </div>
              <div className="member-item-content">
                <a href={item.Slug} target="_blank">
                  <p className="member-item-title">{item.Title}</p>
                </a>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }
}
export default NewsItem;
