import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import WorkMenu from "../menus/workMenu";
import Modal from "../../common/modal/modal";

class WorkGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => this.setState({ showModal: !this.state.showModal });
  render() {
    const { showModal } = this.state;
    return (
      <div>
         {this.props.children}
        <WorkMenu />
      </div>
    );
  }
}

export default WorkGroup;
