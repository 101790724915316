import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

class SecretaryYased extends React.Component {
  render() {
    return (
      <div className="about-container">
        <h1 className="about-title">Genel Sekreterlik</h1>
        <div className="table-wTitle-box">
          <Scrollbars autoHide={false} autoHeight autoHeightMax={`70vh`}>
            <div className="table-wTitle">
              <table className="table-wTitle-head">
                <tr>
                  <th className="about-table-row-first">
                    YASED DENETİM KURULU
                  </th>
                  <th className="about-table-row-second">ÜNVAN</th>
                </tr>
              </table>
              <table className="table-wTitle-body">
                <tr>
                  <td className="about-table-row-first">
                    YASED DENETİM KURULU
                  </td>
                  <td className="about-table-row-second">ÜNVAN</td>
                </tr>
                <tr>
                  <td className="about-table-row-first">
                    YASED DENETİM KURULU
                  </td>
                  <td className="about-table-row-second">ÜNVAN</td>
                </tr>
              </table>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default SecretaryYased;
