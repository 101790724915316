import React from "react";
import NewsItem from "./newsItem";
import Slider from "react-slick";
import moment from "moment";
import Api from "../../../common/api/api";

class News extends React.Component {
  constructor(props) {
    super(props);

    const lang = localStorage.getItem("lang");

    if (lang == "en") {
      this.TABS = [
        {
          label: "All",
          value: 1,
          tabOrderVal: "",
        },
      ];
    } else {
      this.TABS = [
        {
          label: "Hepsi",
          value: 1,
          tabOrderVal: "",
        },
      ];
    }

    this.state = {
      activeTab: 1,
      activeItem: [],
      data: "",
      tabsRefill: false,
    };
  }

  componentDidMount() {
    let users = JSON.parse(localStorage.getItem("user")) || [];
    if (users.length != 0) {
      Api.get("/news/getlist/10?login=" + users.Email).then((res) => {
        this.setState({
          data: res.data,
          activeItem: res.data.items,
        });
        this.fillTabs();
      });
    } else {
      Api.get("/news/getlist/10").then((res) => {
        this.setState({
          data: res.data,
          activeItem: res.data.items,
        });
        this.fillTabs();
      });
    }
  }

  fillTabs = () => {
    const lang = localStorage.getItem("lang");
    const months = [];
    if (lang == "en") {
      months.push("January");
      months.push("February");
      months.push("March");
      months.push("April");
      months.push("May");
      months.push("June");
      months.push("July");
      months.push("August");
      months.push("September");
      months.push("October");
      months.push("November");
      months.push("December");
    } else {
      months.push("Ocak");
      months.push("Şubat");
      months.push("Mart");
      months.push("Nisan");
      months.push("Mayıs");
      months.push("Haziran");
      months.push("Temmuz");
      months.push("Ağustos");
      months.push("Eylül");
      months.push("Ekim");
      months.push("Kasım");
      months.push("Aralık");
    }
    let items = this.state.data.items;

    items.map((val, index) => {
      let cleanDate = moment(val.Date).month();

      let filterTabData = this.TABS.filter(
        (tab) => tab.tabOrderVal === cleanDate
      );

      if (!filterTabData.length) {
        if (lang == "en") {
          if (val.Title_EN) {
            this.TABS.push({
              label: months[cleanDate],
              value: 2 + index,
              tabOrderVal: cleanDate,
            });
          }
        } else {
          this.TABS.push({
            label: months[cleanDate],
            value: 2 + index,
            tabOrderVal: cleanDate,
          });
        }
      }

      val["orderVal"] = cleanDate;
    });
    this.setState({ tabsRefill: true });
  };

  activeTab = (actVal) => {
    let tabOrder = this.TABS.filter((tab) => tab.value === actVal),
      filteredItems = [];
    if (actVal === 1) {
      filteredItems = this.state.data.items;
    } else {
      filteredItems = this.state.data.items.filter((item) => {
        if (item.orderVal === tabOrder[0].tabOrderVal) {
          return item;
        }
      });
    }

    this.setState({
      activeTab: actVal,
      activeItem: filteredItems,
    });
  };

  render() {
    const { activeItem, tabsRefill, activeTab } = this.state;
    const settings = {
      className: "slider variable-width",
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
    };

    return (
      <div className="news-cover">
        <div className="main-tab">
          {tabsRefill && (
            <Slider {...settings}>
              {this.TABS.map(({ label, value }) => (
                <div key={value}>
                  <div
                    className={`tab ${
                      value === activeTab ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      this.activeTab(value);
                    }}
                    key={`${value}key`}
                  >
                    {label === "Hepsi" ? (
                      <div className="main-tab-label">
                        <span>
                          <i className="icon-base icon-calendar-all"></i>
                        </span>
                        <span>{label}</span>
                      </div>
                    ) : (
                      <div className="main-tab-label">
                        <span>
                          <i className="icon-base icon-calendar"></i>
                        </span>
                        <span>{label}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className="main-tab-content">
          {activeItem.length && <NewsItem item={activeItem} />}
        </div>
      </div>
    );
  }
}
export default News;
