import React from "react";
import UnitedItem from "./unitedItem";
import Slider from "react-slick";
import Api from "../../common/api/api";

class United extends React.Component {
  constructor(props) {
    super(props);

    this.TABS = [
      {
        label: "Hepsi",
        value: 1,
        tabOrderVal: "",
      },
    ];

    this.state = {
      activeTab: 1,
      activeItem: [],
      data: "",
      tabsRefill: false,
    };
  }

  componentDidMount() {
    Api.get("/uniteds/getlist/").then((res) => {
      this.setState({
        data: res.data,
        activeItem: res.data.items,
      });
    });
  }
  fillTabs = () => {
    const months = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    let items = this.state.data.items;

    items.map((val, index) => {
      let cleanDate = moment(val.Date).month();
      this.TABS.push({
        label: months[cleanDate],
        value: 2 + index,
        tabOrderVal: cleanDate,
      });

      val["orderVal"] = cleanDate;
    });
    this.setState({ tabsRefill: true });
  };

  activeTab = (actVal) => {
    let tabOrder = this.TABS.filter((tab) => tab.value === actVal),
      filteredItems = [];
    if (actVal === 1) {
      filteredItems = this.state.data.items;
    } else {
      filteredItems = this.state.data.items.filter((item) => {
        if (item.orderVal === tabOrder[0].tabOrderVal) {
          return item;
        }
      });
    }

    this.setState({
      activeTab: actVal,
      activeItem: filteredItems,
    });
  };

  render() {
    const { activeItem, tabsRefill, activeTab } = this.state;
    const settings = {
      className: "slider variable-width",
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
    };
    return (
      <div className="news-cover">
        <div className="main-tab">
          {tabsRefill && (
            <Slider {...settings}>
              {this.TABS.map(({ label, value }) => (
                <div>
                  <div
                    className={`tab ${
                      value === activeTab ? "selected-tab" : ""
                    }`}
                    onClick={() => {
                      this.activeTab(value);
                    }}
                    key={`${value}key`}
                  >
                    {label === "Hepsi" ? (
                      <div className="main-tab-label">
                        <span>
                          <i className="icon-base icon-calendar-all"></i>
                        </span>
                        <span>{label}</span>
                      </div>
                    ) : (
                      <div className="main-tab-label">
                        <span>
                          <i className="icon-base icon-calendar"></i>
                        </span>
                        <span>{label}</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
        <div className="main-tab-content">
          {activeItem.length && <UnitedItem item={activeItem} />}
        </div>
      </div>
    );
  }
}
export default United;
