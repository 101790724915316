import React from "react";
import Modal from "../../common/modal/modal";
import { Form, Field } from "react-final-form";
import Api from "../../common/api/api";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showModal: false,
      isHidden: true,
    };
  }
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      isHidden: true,
    });
  };

  render() {
    const modalChange = () => {
      this.setState({
        showModal: !this.state.showModal,
        isHidden: true,
      });
    };
    const formSubmit = (values) => {
      var payload = {
        Email: values.Email,
        Password: values.Password,
      };
      Api.post("home/login", payload)
        .then(function (response) {
          localStorage.removeItem("user");
          if (response.data.status == "success") {
            console.log(response.data);
            let user = {
              FirstName: response.data.user.FirstName,
              LastName: response.data.user.LastName,
              Email: response.data.user.Email,
              MobilePhone: response.data.user.MobilePhone,
              Company: response.data.user.Company,
              Title: response.data.user.Title,
              ID: response.data.user.ID,
            };
            localStorage.setItem("user", JSON.stringify(user));
            modalChange();
            window.location.reload(false);
          } else {
            alert("Kullanıcı adı yada şifre yanlış");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const lang = localStorage.getItem("lang");

    const getButton = (element) => {
      if (lang == "en") {
        return " Login";
      } else {
        return "Giriş Yap";
      }
    };

    const getTitle = (element) => {
      if (lang == "en") {
        return <h2 className="setting-modal-title">User Login</h2>;
      } else {
        return <h2 className="setting-modal-title">Üye Girişi</h2>;
      }
    };

    const getItems = (element) => {
      if (lang == "en") {
        return (
          <div>
            <Field
              name="Email"
              component="input"
              type="text"
              placeholder="E-Mail"
            />
            <Field
              name="Password"
              component="input"
              type="password"
              placeholder="Password"
            />
          </div>
        );
      } else {
        return (
          <div>
            <Field
              name="Email"
              component="input"
              type="text"
              placeholder="E-posta"
            />
            <Field
              name="Password"
              component="input"
              type="password"
              placeholder="Şifre"
            />
          </div>
        );
      }
    };

    const { showModal } = this.state;
    return (
      <div>
        <button className="general-btn" onClick={this.toggleModal}>
          <i className="icon-base icon-login"></i>
        </button>
        {showModal ? (
          <Modal>
            <div
              className={`setting-modal ${
                !this.state.isHidden ? "hidden" : ""
              }`}
            >
              <button
                className="close-modal"
                onClick={this.toggleModal}
              ></button>
              {getTitle()}
              <Form
                onSubmit={formSubmit}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {getItems()}

                    <div className="buttons login-button">
                      <button
                        type="button"
                        className="btn-form-action"
                        onClick={form.submit}
                      >
                        {getButton()}
                      </button>
                    </div>
                  </form>
                )}
              />
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
export default Login;
