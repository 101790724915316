import React from "react";
import MainSlide from "./mainSlide";
import HomeNewsSlide from "./homeNewsSlide";
import MainCalendar from "./mainCalendar";

class Home extends React.Component {
  render() {
    return (
      <div className="home-content-area">
        <MainSlide />
        <HomeNewsSlide />
        <MainCalendar />
      </div>
    );
  }
}
export default Home;
