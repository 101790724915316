import React from "react";
import { Form, Field } from "react-final-form";
import Api from "../../common/api/api";

class ProfileUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Firstname: "uralozden",
      LastName: "",
      Company: "",
      Email: "",
      MobilePhone: "",
      Title: "",
    };
  }

  render() {
    const lang = localStorage.getItem("lang");

    const getFormElement = (users) => {
      if (lang == "en") {
        return (
          <div className="elements">
            <div>
              <Field
                name="FirstName"
                component="input"
                type="text"
                placeholder="First Name"
                defaultValue={users.FirstName}
              />
            </div>
            <div>
              <Field
                name="LastName"
                component="input"
                type="text"
                placeholder="Last Name"
                defaultValue={users.LastName}
              />
            </div>
            <div>
              <Field
                name="Email"
                component="input"
                type="text"
                placeholder="E-posta"
                defaultValue={users.Email}
              />
            </div>
            <div>
              <Field
                name="MobilePhone"
                component="input"
                type="text"
                placeholder="Mobile Phone"
                defaultValue={users.MobilePhone}
              />
            </div>
            <div>
              <Field
                name="Company"
                component="input"
                type="text"
                placeholder="Company"
                defaultValue={users.Company}
              />
            </div>
            <div>
              <Field
                name="Title"
                component="input"
                type="text"
                placeholder="Title"
                defaultValue={users.Title}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div className="elements">
            <div>
              <Field
                name="FirstName"
                component="input"
                type="text"
                placeholder="Ad"
                defaultValue={users.FirstName}
              />
            </div>
            <div>
              <Field
                name="LastName"
                component="input"
                type="text"
                placeholder="Soyad"
                defaultValue={users.LastName}
              />
            </div>
            <div>
              <Field
                name="Email"
                component="input"
                type="text"
                placeholder="E-mail"
                defaultValue={users.Email}
              />
            </div>
            <div>
              <Field
                name="MobilePhone"
                component="input"
                type="text"
                placeholder="Telefon"
                defaultValue={users.MobilePhone}
              />
            </div>
            <div>
              <Field
                name="Company"
                component="input"
                type="text"
                placeholder="Şirket"
                defaultValue={users.Company}
              />
            </div>
            <div>
              <Field
                name="Title"
                component="input"
                type="text"
                placeholder="Ünvan"
                defaultValue={users.Title}
              />
            </div>
          </div>
        );
      }
    };

    const getButton = (element) => {
      if (lang == "en") {
        return "Update";
      } else {
        return "Güncelle";
      }
    };

    const users = JSON.parse(localStorage.getItem("user")) || [];
    const formSubmit = (values) => {
      var payload = {
        FirstName: values.FirstName,
        LastName: values.LastName,
        Company: values.Company,
        Email: users.Email,
        MobilePhone: values.MobilePhone,
        Title: values.Title,
        ID: users.ID,
      };
      Api.post("home/edit", payload)
        .then(function (response) {
          if (response.data.status == "success") {
            localStorage.removeItem("user");
            alert("Başarıyla güncellendi");
            localStorage.setItem("user", JSON.stringify(payload));
          } else {
            alert("Kullanıcı adı yada şifre yanlış");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    return (
      <div>
        <Form
          onSubmit={formSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              {getFormElement(users)}

              <div className="buttons">
                <button
                  className="btn-form-action"
                  type="submit"
                  onClick={form.submit}
                >
                  {getButton()}
                </button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
export default ProfileUpdate;
