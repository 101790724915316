import React from "react";
import Calendar from "react-calendar";
import Modal from "../../common/modal/modal";
import EventCalendar from "./eventCalendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import Api from "../../common/api/api";


class MainCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(),
      showModal: false,
      data: []
    };

    this.data=[];
  }

  onClick = (value, event) => {
    this.setState({ value:value });
    this.toggleModal();
  };

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  componentDidMount(){
    Api.get("activities/getActivities").then((res) => {
      let date = []
      res.data.forEach(element => {
        date.push(moment(element.Date).format('DD-MM-YYYY'));
      });
      this.setState({data: date});
    });
  }

getdata(){
  Api.get("activities/getActivities").then((res) => {
    res.data.forEach(element => {
      let date = []
      this.data.push(moment(element.Date).format('DD-MM-YYYY'));
    });
  });
  return this.data;
}



  render() {
   
    // mark data update
    const lang = localStorage.getItem('lang');
    const lang_string = "";
    const calendarTitle = () => {
      if (lang == "en"){
        return (
          <h4 className="main-calendar-title">ACTIVITY CALENDAR</h4>
        )
      }else{
        return (
          <h4 className="main-calendar-title">ETKİNLİK TAKVİMİ</h4>
        )
      }
    }

    const getLang = () => {
      if (lang == "en"){
        return "en-EN"
      }else{
        return "tr-TR"
      }
    }

    const { value, showModal } = this.state;
    return (
      <div>
        <div className="main-calendar">
          {calendarTitle()}
          <Calendar
            onClickDay={this.onClick}
            style={{ height: "24vh" }}
            locale={getLang()}
            tileClassName={({ date, view }) => {
            if(this.state.data.find(x=>x===moment(date).format("DD-MM-YYYY"))){
            return  'highlight'
            }
          }}
          />
        </div>
        {showModal ? (
          <Modal>
            <div className="calendar-modal">
              <button
                className="close-modal"
                onClick={this.toggleModal}
              ></button>
              <EventCalendar value={this.state.value} />
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
export default MainCalendar;
