import React from "react";
import Slider from "react-slick";

class NewsItem extends React.Component {
  render() {
    const lang = localStorage.getItem("lang");
    const getItem = (item, key) => {
      if (lang == "en") {
        if (item.Title_EN != null) {
          return (
            <div key={key}>
              <div className="news-item">
                <div className="news-item-img-box">
                  <a href={"/news/" + item.Slug_EN}>
                    <img
                      src={"https://yased-api.yased.org.tr/" + item.Image}
                      className="news-item-img"
                      alt=""
                    />
                  </a>
                </div>
                <div className="news-item-content">
                  <a href={"/news/" + item.Slug_EN}>
                    <p className="news-item-title">{item.Title_EN}</p>
                  </a>
                  <p
                    className="news-item-text"
                    dangerouslySetInnerHTML={{ __html: item.Spot_EN }}
                  ></p>
                  <a href={"/news/" + item.Slug_EN}>
                    <span className="news-item-more">read more</span>
                  </a>
                  <span className="news-item-share">
                    <i className="icon-base icon-share"></i>
                  </span>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div key={key}>
            <div className="news-item">
              <div className="news-item-img-box">
                <a href={"/kuresel-tedarik-zinciri/haberler/" + item.Slug}>
                  <img
                    src={"https://yased-api.yased.org.tr/" + item.Image}
                    className="news-item-img"
                    alt=""
                  />
                </a>
              </div>
              <div className="news-item-content">
                <a href={"/kuresel-tedarik-zinciri/haberler/" + item.Slug}>
                  <p className="news-item-title">{item.Title}</p>
                </a>
                <p
                  className="news-item-text"
                  dangerouslySetInnerHTML={{ __html: item.Spot }}
                ></p>
                <a href={"/kuresel-tedarik-zinciri/haberler/" + item.Slug}>
                  <span className="news-item-more">devamı için tıklayınız</span>
                </a>
                <span className="news-item-share">
                  <i className="icon-base icon-share"></i>
                </span>
              </div>
            </div>
          </div>
        );
      }
    };

    const items = this.props.item;
    const settings = {
      slidesToShow: 2,
      speed: 500,
      rows: 3,
      slidesPerRow: 1,
      infinite: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          {items.map((item, key) => getItem(item, key))}
        </Slider>
      </div>
    );
  }
}
export default NewsItem;
