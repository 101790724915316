import React from "react";

class BoardYased extends React.Component {
  render() {
    return (
      <div className="about-container">
        <h1 className="about-title">Yönetim Kurulu</h1>
        <div className="about-image-box">
          <img src="http://placecorgi.com/1200/675" />
        </div>
      </div>
    );
  }
}

export default BoardYased;
