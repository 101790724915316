import React from "react";
import Slider from "react-slick";

class AcademySlider extends React.Component {
  render() {
    const lang = localStorage.getItem("lang");
    const getItem = (item, key) => {
      return (
        <div key={key}>
          <img
            src={"https://yased-api.yased.org.tr/" + item.image}
            className="news-item-img"
            alt=""
          />
        </div>
      );
    };
    const items = this.props.item;
    const settings = {
      slidesToShow: 1,
      speed: 500,
      rows: 1,
      slidesPerRow: 1,
      infinite: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          {items.map((item, key) => getItem(item, key))}
        </Slider>
      </div>
    );
  }
}
export default AcademySlider;
