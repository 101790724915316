import React from "react";
import { Router } from "@reach/router";
import DigitalMenu from "../menus/digitalMenu";
import Api from "../../common/api/api";
import DigitalDetail from "./digitalDetail";

class Digital extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenu: [],
      submenuAlt: [],
    };
    const menu = [];
  }
  componentDidMount() {
    this.getMenu();
  }
  getMenu = () => {
    let submenuAltArr = [];
    Api.get("/contents/getsubmenu/75").then((res) => {
      this.setState({ submenu: res.data, submenuAlt: submenuAltArr });
    });
  };

  render() {
    const data = this.state.submenu;
    return (
      <div>
        <Router>
          <DigitalDetail path={"/:id"} />
        </Router>
        <DigitalMenu submenu={data} />
      </div>
    );
  }
}

export default Digital;
