import React from "react";
import Slider from "react-slick";
import Api from "../../common/api/api";

class MainSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliders: [],
    };
  }
  
  componentDidMount() {
    Api.get("/slider/GetSlider/").then((res) => {
      const lang = localStorage.getItem("lang");
      if (lang == "en") {
      this.setState({ sliders: res.data.slider_en });
      }else{
        this.setState({ sliders: res.data.slider_tr });
      }
    });
  }

  render() {
    const lang = localStorage.getItem("lang");
    const calendarTitle = () => {
      if (lang == "en") {
        return <div className="main-slider-dot-title">HIGHLIGHTS </div>;
      } else {
        return <div className="main-slider-dot-title">ÖNE ÇIKANLAR </div>;
      }
    };

    const getItems = (sliderElement, i) => {
      if (lang == "en") {
        if (sliderElement.Title_EN != null) {
          return (
            <div className="main-slider-item" key={i}>
              <a href={sliderElement.Link_EN}>
                <img
                  src={
                    "https://yased-api.yased.org.tr" + sliderElement.ImageURL
                  }
                  alt=""
                />
                <p className="main-slider-title">{sliderElement.Title_EN}</p>
              </a>
            </div>
          );
        }
      } else {
        return (
          <div className="main-slider-item" key={i}>
            <a href={sliderElement.Link}>
              <img
                src={"https://yased-api.yased.org.tr" + sliderElement.ImageURL}
                alt=""
              />
              <p className="main-slider-title">{sliderElement.Title}</p>
            </a>
          </div>
        );
      }
    };

    const getTitle = (sliderElement) => {
      if (lang == "en") {
        if (sliderElement.Title_EN != null) {
          return (
            <p className="main-slider-dots-text">{sliderElement.Title_EN}</p>
          );
        }
      } else {
        return <p className="main-slider-dots-text">{sliderElement.Title}</p>;
      }
    };

    const filtered = [];
    const data = this.state.sliders;
    if (lang == "en") {
    if(data){
      data.forEach((element) => {
        if (element.Title_EN != null) {
          filtered.push(element);
        }
      });
    }
    } else {
      if(data){
        data.forEach((element) => {
          if (element.Title != null) {
            filtered.push(element);
          }
        });
      }

    }

    const settings = {
      dots: true,
      vertical: true,
      verticalSwiping: true,
      infinite: true,
      speed: 750,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 6000,
          settings: {
            customPaging: function (i) {
              return (
                <a href="/" key={i}>
                  {getTitle(filtered[i])}
                </a>
              );
            },
            dotsClass: "main-slider-dots-list",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 1,
            dots: false,
            adaptiveHeight: true,
          },
        },
      ],
    };

    return (
      <div className="main-slider-cover">
        {filtered.length && (
          <Slider {...settings}>
            {filtered
              .slice(0, 4)
              .map((sliderElement, i) => getItems(sliderElement, i))}
          </Slider>
        )}
        {calendarTitle()}
      </div>
    );
  }
}
export default MainSlide;
