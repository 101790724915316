import React from "react";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars";
import Api from "../../common/api/api";
import NewSlider from "../news/newSlider";

class UnitedDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
      slider:[]
    };

    this.sliders = []
  }

  componentDidMount() {
    Api.get("uniteds/getdetailgallery?slug=" + this.props.id).then((res) => {
      const lang = localStorage.getItem('lang');
      if (lang == "en"){
        if(res.data.united.Title_EN == null){
          window.location.href = "/";
        }
      }
    this.setState({ content: res.data.united,
      slider: res.data.gallery  });
    });
  }

  render() {
    
    const lang = localStorage.getItem('lang');
    const sliders = this.state.slider;
    if(this.state.content.Image != undefined){
      sliders.push({
        image: this.state.content.Image,
      });
    }
    sliders.reverse();

    const getItem = (item) => {
      if (lang == "en"){
        return (
          <div className="insight-slider-hold">
            <h2 className="insight-title">{item.Title_EN}</h2>
            <div
              className="insight-slider-text"
              dangerouslySetInnerHTML={{ __html: item.Spot_EN }}
            ></div>
            <div className="insight-slider-action">
            {item.Video_EN ? (
              <a
                href={
                  item.Video_EN
                }
                target="_blank"
              >
                <span className="insight-slider-play"></span>
              </a>
            ):null}
            {item.Docs_EN ? (
              <a
                href={
                  "https://yased-api.yased.org.tr/" +
                  item.Docs_EN
                }
                target="_blank"
              >
                <i className="icon-base icon-pdf"></i>
                <span className="insight-slider-down-text">
                  Click Meeting Note
                </span>
              </a>
            ):null}
            </div>
          </div>
        
      );

      }else{
        return (
          <div className="insight-slider-hold">
          <h2 className="insight-title">{item.Title}</h2>
          <div
            className="insight-slider-text"
            dangerouslySetInnerHTML={{ __html: item.Spot }}
          ></div>
          <div className="insight-slider-action">
          {item.Video ? (
            <a
              href={
                item.Video
              }
              target="_blank"
            >
              <span className="insight-slider-play"></span>
            </a>
          ):null}
          {item.Docs ? (
            <a
              href={
                "https://yased-api.yased.org.tr/" +
                item.Docs
              }
              target="_blank"
            >
              <i className="icon-base icon-pdf"></i>
              <span className="insight-slider-down-text">
                Toplantı notu için tıklayın
              </span>
            </a>
          ):null}
          </div>
        </div>
        );
      }
    }

    const getDesc = (item)=> {
      if (lang == "en"){
        return(
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.content.Description_EN,
            }}
          ></div>
        )
      }else{
        return(
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.content.Description,
            }}
          ></div>
        )
      }
    }

    
    const settings = {
      slidesToShow: 1,
      speed: 500,
      slidesToScroll: 1,
      infinite: false,
    };


    return (
      <div className="fullpage-container">
        <div className="insight-top">
          <div className="insight-image">
          {sliders.length && <NewSlider item={sliders} />}
          </div>
          <div className="insight-slider-box">
            <Slider {...settings}>
              <div>
                {getItem(this.state.content)}
              </div>
            </Slider>
          </div>
        </div>
        <div className="insight-text-container">
          <Scrollbars autoHide={false} autoHeight autoHeightMax={`30vh`}>
            <div className="insight-text">
              {getDesc(this.state.content)}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default UnitedDetail;
