import React from "react";
import { Router } from "@reach/router";
import AboutMenu from "../menus/aboutMenu";
import Api from "../../common/api/api";
import AboutYased from "./aboutYased";
import MemberYased from "./memberYased";
import BoardYased from "./boardYased";
import SecretaryYased from "./secretaryYased";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenu: [],
      submenuAlt: [],
    };
    const menu = [];
  }
  componentDidMount() {
    this.getMenu();
  }
  getMenu = () => {
    let submenuAltArr = [];
    Api.get("/contents/getsubmenu/1").then((res) => {
      this.setState({ submenu: res.data, submenuAlt: submenuAltArr });
    });
  };

  render() {
    const data = this.state.submenu;
    return (
      <div>
        <Router>
          <AboutYased path={"/:id"} />
          <MemberYased path="/uyeler" />
          <MemberYased path="/members" />
        </Router>
        <AboutMenu submenu={data} />
      </div>
    );
  }
}

export default About;
