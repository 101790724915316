import React from "react";
import { Link } from "@reach/router";
import axios from "axios";
import Contact from "./contact";
import Api from "../../common/api/api";


class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: '',
      cookie: false,
      isOpen: false
    };
  }

  handleCookie = (data) => {
    this.setState({ isOpen: data });
  }

  componentDidMount() {
    /* Api.get("home").then((res) => {
      let dat = JSON.parse(res.data.usd);
      let usd = dat.quotes.USDTRY;
      let dat2 = JSON.parse(res.data.eur);
      let eur = dat2.quotes.EURTRY;
      let dat3 = JSON.parse(res.data.gbp);
      let gbp = dat3.quotes.GBPTRY;

      let data = {
        usd:usd,
        eur:eur,
        gbp:gbp,
      }
      this.setdata(data);
    }); */
    

    if(localStorage.getItem('cookie') == "true"){
      this.handleCookie(true);
    }

  }
  /*
  setdata(data){
    this.setState({ currency: data });
  }
  */


  setCookie(data){
    if(data === true){
      localStorage.setItem('cookie', data);
    }
    this.handleCookie(true);
  }


  render() {
    const lang = localStorage.getItem('lang');
    

    const footerText = () => {
      if (lang == "en"){
        return (
          <p className="footer-copyright">
          YASED International Inverstors Association. All rights reserved. © 2022
          </p>
        )
      }else{
        return (
          <p className="footer-copyright">
          YASED Uluslararası Yatırımcılar Derneği. Tüm hakları saklıdır. © 2022
          </p>
        )
      }
    }

    const cookietext = () => {
      if (lang == "en"){
        return (
          <div className="cookie-popup"  style={{ display: this.state.isOpen ? "none" : "flex" }}>
            <div className="cookie-text">
              <p className="footer-cookie">
              We use cookies to improve your experience on our site. Cookies enable you to experience certain features better and use the site effectively. By clicking "Accept" you will accept all cookies used on our site. 
              </p>
              <p>For detailed information, please check our <a href="https://yased.org.tr/about-us/privacy-policy">Privacy </a>and <a href="https://yased.org.tr/about-us/cookie-policy">  Cookie Policy.</a> </p>
            </div>
            <button className="button" onClick={ () => {this.setCookie(true)}}>Approve</button>
            <button className="close" onClick={ () => {this.handleCookie(true)}}>x</button>
          </div>
        )
      }else{
        return (
          <div className="cookie-popup"  style={{ display: this.state.isOpen ? "none" : "flex" }}>
            <div className="cookie-text">
              <p className="footer-cookie">
              Sitemiz içerisindeki deneyiminizi iyileştirmek için çerez kullanıyoruz. Çerezler, belirli özellikleri daha iyi deneyimlemenizi ve sitenin etkin bir şekilde kullanılmasını sağlarlar. “Kabul et”e tıklayarak tüm çerezleri kabul edebilirsiniz. 
              </p>
              <p>Detaylı bilgi almak için  <a href="https://yased.org.tr/hakkimizda/gizlilik-politikasi">Gizlilik </a>ve <a href="https://yased.org.tr/hakkimizda/cerez-politikasi">Çerez Politikası </a>'nı inceleyiniz. </p>
            </div>
            <button className="button" onClick={ () => {this.setCookie(true)}}>Kabul Et</button>
            <button className="close" onClick={ () => {this.handleCookie(true)}}>x</button>
          </div>
        )
      }
    }


    /*
    let usd = "";
    let eur = "";
    let gbp = "";
    if (this.state.currency){
      
       usd = this.state.currency.usd;
       eur = this.state.currency.eur;
       gbp = this.state.currency.gbp;
    }
    */
  
    return (
      <footer>
        <div className="footer-left">
          {/* <div className="footer-stock">
            <ul className="stock-list">
              <marquee>
                <li className="stock-list-item">
                  <span className="stock-list-type">USD</span>
                  <span className="stock-list-icon">
                    <i className="stock-plus"></i>
                  </span>
                  <span className="stock-list-value">{usd}</span>
                </li>
                <li className="stock-list-item">
                  <span className="stock-list-type">EUR</span>
                  <span className="stock-list-icon">
                    <i className="stock-plus"></i>
                  </span>
                  <span className="stock-list-value">{eur}</span>
                </li>
                <li className="stock-list-item">
                  <span className="stock-list-type">GBP</span>
                  <span className="stock-list-icon">
                    <i className="stock-plus"></i>
                  </span>
                  <span className="stock-list-value">{gbp}</span>
                </li>
                
              </marquee>
            </ul>
          </div> */}
          {footerText()}
        </div>
        <div className="footer-right">

          {cookietext()}

          

          <a href="https://twitter.com/YASEDorgtr" className="footer-twitter" target="_blank">
            <i className="icon-base icon-twitter"></i>
          </a>
          <a href="https://www.linkedin.com/company/yased-uluslararası-yatırımcılar-derneği" className="footer-linkedin" target="_blank">
            <i className="icon-base icon-linkedin"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCLeBxIfOGKrskB3HjftD87g" className="footer-youtube" target="_blank">
            <i className="icon-base icon-youtube"></i>
          </a>
          <Link to="/" className="footer-connect">
            <Contact />
          </Link>
        </div>
      </footer>
    );
  }
}

export default Footer;
