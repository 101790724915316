import React from "react";
import Slider from "react-slick";
import Api from "../../common/api/api";
class HomeNewsSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliders: [],
      news: "",
      usernews: "",
      members: "",
      international: "",
      academy: "",
    };
  }

  componentDidMount() {
    let users = JSON.parse(localStorage.getItem("user")) || [];
    if (users.length != 0) {
      Api.get("/slider/getbottomslider/?login=" + users.Email).then((res) => {
        this.newsChange(res.data.news, res.data.news_en);
        this.userNewsChange(res.data.usernews, res.data.usernews_en);
        this.membersChange(res.data.member);
        this.interChange(res.data.works);
        this.acedemyChange(res.data.academy, res.data.academy_en);
      });
    } else {
      Api.get("/slider/getbottomslider/").then((res) => {
        this.newsChange(res.data.news, res.data.news_en);
        this.userNewsChange(res.data.usernews, res.data.usernews_en);
        this.membersChange(res.data.member);
        this.interChange(res.data.works, res.data.works_en);
        this.acedemyChange(res.data.academy, res.data.academy_en);
      });
    }
  }

  acedemyChange(data, data_en) {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.setState({ academy: data_en });
    } else {
      this.setState({ academy: data });
    }
  }

  newsChange(data, data_en) {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.setState({ news: data_en });
    } else {
      this.setState({ news: data });
    }
  }

  userNewsChange(data, data_en) {
    const lang = localStorage.getItem("lang");
    if (lang == "en") {
      this.setState({ usernews: data_en });
    } else {
      this.setState({ usernews: data });
    }
  }

  membersChange(data) {
    this.setState({ members: data });
  }

  interChange(data) {
    this.setState({ international: data });
  }

  render() {
    const lang = localStorage.getItem("lang");
    const workTitle = (item) => {
      if (lang == "en") {
        if (item && item.Title_EN != undefined) {
          if (item && item.Image != undefined) {
            return (
              <div className="home-news-item">
                <a href="/yased-calisiyor" className="home-news-title">
                  YASED IS WORKING
                </a>
                <div className="responsive-img">
                  <a href={"/yased-calisiyor/" + item.Slug_EN}>
                    <img
                      src={"https://yased-api.yased.org.tr/" + item.Image}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            );
          }
        }
      } else {
        if (item && item.Image != undefined) {
          return (
            <div className="home-news-item">
              <a href="/yased-calisiyor" className="home-news-title">
                YASED ÇALIŞIYOR
              </a>
              <div className="responsive-img">
                <a href={"/yased-calisiyor/" + item.Slug}>
                  <img
                    src={"https://yased-api.yased.org.tr/" + item.Image}
                    alt=""
                  />
                </a>
              </div>
            </div>
          );
        }
      }
    };

    const DigitalizaionCoursel = (item) => {
      if (lang == "en") {
        return (
          <>
            <a href="#" className="home-news-title">
              PLATFORMS
            </a>
            <a href="/global-supply-chain-platform/global-supply-chain-platform">
              <img src="/images/global-supply-chain.png" />
            </a>
            <a href="/digitalization-platform/digitalization-platform">
              <img src="/images/digitalization.png" />
            </a>
            <a href="/the-sustainable-development/sustainable-development">
              <img src="/images/sustainable-dev.png" />
            </a>
            
            { <a href="/human-capital-development/human-capital-development">
              <img src="/images/human-capital.png" />
            </a>  }

          </>
        );
      } else {
        return (
          <>
            <a href="#" className="home-news-title">
              PLATFORMLAR
            </a>
            <a href="/kuresel-tedarik-zinciri/kuresel-tedarik-zinciri-giris">
              <img src="/images/kuresel-tedarik-zinciri.png" />
            </a>
            <a href="/dijital-donusum/dijital-donusum-giris">
              <img src="/images/dijital-donusum.png" />
            </a>
            <a href="/surdurulebilir-kalkinma/surdurulebilir-kalkinma-giris">
              <img src="/images/surdurulebilir-kalkinma.png" />
            </a>

            { <a href="/insan-sermayesinin-gelistirilmesi/insan-sermayesinin-gelistirilmesi-giris">
              <img src="/images/insan-sermayesinin-gelistirilmesi.png" />
            </a> } 
          </>
        );
      }
    };

    const userNewsTitle = (item) => {
      if (lang == "en") {
        if (item && item.Title_EN != undefined) {
          return (
            <div className="home-news-item">
              <a href="uyelerimizden-haberler" className="home-news-title">
                NEWS FROM MEMBERS
              </a>
              <div className="responsive-img">
                <a href={"/uyelerimizden-haberler/" + item.Slug_EN}>
                  <img
                    src={"https://yased-api.yased.org.tr/" + item.Image}
                    alt=""
                  />
                </a>
              </div>
            </div>
          );
        }
      } else {
        if (item && item.Image != undefined) {
          return (
            <div className="home-news-item">
              <a href="uyelerimizden-haberler" className="home-news-title">
                ÜYELERİMİZDEN HABERLER
              </a>
              <div className="responsive-img">
                <a href={"/uyelerimizden-haberler/" + item.Slug}>
                  <img
                    src={"https://yased-api.yased.org.tr/" + item.Image}
                    alt=""
                  />
                </a>
              </div>
            </div>
          );
        }
      }
    };

    const getAcademy = (item) => {
      if (lang == "en") {
        if (item && item.Title_EN !== undefined) {
          return (
            <div className="home-news-item">
              <a href="yased-academy" className="home-news-title">
                YASED ACADEMY
              </a>
              <div className="responsive-img">
                <a href={"/yased-academy/" + item.Slug_EN}>
                  <p>{item.Title_EN}</p>
                  <img
                    src={"https://yased-api.yased.org.tr/" + item.Image}
                    alt=""
                  />
                </a>
              </div>
            </div>
          );
        }
      } else {
        if (item && item.Image != undefined) {
          return (
            <div className="home-news-item">
              <a href="yased-akademi" className="home-news-title">
                YASED AKADEMİ
              </a>
              <div className="responsive-img">
                <a href={"/yased-akademi/" + item.Slug}>
                  <img
                    src={"https://yased-api.yased.org.tr/" + item.Image}
                    alt=""
                  />
                </a>
              </div>
            </div>
          );
        }
      }
    };

    let MemerCo = () => {
      if (this.state.members[0]) {
        return (
          <div className="members-slider">
            <div className="item">
              <a href={"/hakkimizda/uyeler"}>
                <img
                  src={
                    "https://yased-api.yased.org.tr" +
                    this.state.members[0].Image
                  }
                  alt=""
                />
              </a>
            </div>
            <div className="item">
              <a href={"/hakkimizda/uyeler"}>
                <img
                  src={
                    "https://yased-api.yased.org.tr" +
                    this.state.members[1].Image
                  }
                  alt=""
                />
              </a>
            </div>
            <div className="item">
              <a href={"/hakkimizda/uyeler"}>
                <img
                  src={
                    "https://yased-api.yased.org.tr" +
                    this.state.members[2].Image
                  }
                  alt=""
                />
              </a>
            </div>
            <div className="item">
              <a href={"/hakkimizda/uyeler"}>
                <img
                  src={
                    "https://yased-api.yased.org.tr" +
                    this.state.members[3].Image
                  }
                  alt=""
                />
              </a>
            </div>
          </div>
        );
      } else {
        return "";
      }
    };

    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="home-news-cover">
        <Slider {...settings}>
          <div className="slide-group">{DigitalizaionCoursel()}</div>

          <div>{userNewsTitle(this.state.usernews)}</div>

          <div>
            <div className="home-news-item">
              <a href="/hakkimizda/uyeler" className="home-news-title">
                YASED
              </a>
              <MemerCo />
            </div>
          </div>

          <div>{workTitle(this.state.news)}</div>

          <div>{getAcademy(this.state.academy)}</div>
        </Slider>
      </div>
    );
  }
}
export default HomeNewsSlide;
