import React from "react";
import { Link } from "@reach/router";
import Contact from "../footer/contact";
import Api from "../../common/api/api";

class MainMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuState: "close",
      menus: [],
    };
  }

  openMenu = () => {
    if (this.state.menuState === "") {
      this.setState({ menuState: "close" });
    } else {
      this.setState({ menuState: "" });
    }
  };

  componentDidMount() {
    Api.get("/menus/GetHomeMenu/").then((res) => {
      this.setState({ menus: res.data });
    });
  }

  goHome = () => {
    window.location.href = "/";
  };

  Greeting(props, i) {
    let lang = localStorage.getItem("lang");
    let path = window.location.pathname.split("/");
    //new menu item no view on menu
    if (props.Id != 66 && props.Id != 75 && props.Id != 82 && props.Id != 1098) {
      if (lang == "en") {
        let link = props.Link_EN.split("/");
        if (link[1] == "news") {
          return (
            <li
              key={i}
              className={
                path[1] == link[1] &&
                link[2] == undefined &&
                props.target != "_blank"
                  ? "nav-menu-item nav-menu-item-active"
                  : "nav-menu-item"
              }
            >
              <a
                href={props.Link_EN}
                target={props.target == "_blank" ? props.target : ""}
                className="nav-menu-link"
              >
                {props.Name_EN}
              </a>
            </li>
          );
        } else {
          return (
            <li
              key={i}
              className={
                path[1] == link[1] && props.target != "_blank"
                  ? "nav-menu-item nav-menu-item-active"
                  : "nav-menu-item"
              }
            >
              <a
                href={props.Link_EN}
                target={props.target == "_blank" ? props.target : ""}
                className="nav-menu-link"
              >
                {props.Name_EN}
              </a>
            </li>
          );
        }
      } else {
        let link = props.Link.split("/");
        if (link[1] == "haberler") {
          return (
            <li
              key={i}
              className={
                path[1] == link[1] &&
                link[2] == undefined &&
                props.target != "_blank"
                  ? "nav-menu-item nav-menu-item-active"
                  : "nav-menu-item"
              }
            >
              <a
                href={props.Link}
                target={props.target == "_blank" ? props.target : ""}
                className="nav-menu-link"
              >
                {props.Name}
              </a>
            </li>
          );
        } else {
          return (
            <li
              key={i}
              className={
                path[1] == link[1] && props.target != "_blank"
                  ? "nav-menu-item nav-menu-item-active"
                  : "nav-menu-item"
              }
            >
              <a
                href={props.Link}
                target={props.target == "_blank" ? props.target : ""}
                className="nav-menu-link"
              >
                {props.Name}
              </a>
            </li>
          );
        }
      }
    }
  }

  render() {
    const data = this.state.menus;
    return (
      <nav>
        <Link to="/" className="nav-logo" onClick={this.goHome}>
          <img
            src="https://yased-api.yased.org.tr/Content/AdminLayout/assets/images/logo.png"
            alt="yased logo"
          />
        </Link>
        <button className="nav-menu-ham" onClick={this.openMenu}>
          <i className="icon-base icon-ham"></i>
        </button>
        <div className={`nav-menu-mobil ${this.state.menuState}`}>
          <button className="nav-menu-close" onClick={this.openMenu}>
            <i className="icon-base icon-close"></i>
          </button>
          <ul className="nav-menu">
            {data.map((menuElement, i) => this.Greeting(menuElement, i))}
          </ul>
          <div>
            <a href="https://twitter.com/YASEDorgtr" className="menu-twitter" target="_blank">
              <i className="icon-base icon-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/company/yased-uluslararası-yatırımcılar-derneği" className="menu-linkedin" target="_blank">
              <i className="icon-base icon-linkedin"></i>
            </a>
            <a href="https://www.youtube.com/channel/UCLeBxIfOGKrskB3HjftD87g" className="menu-youtube" target="_blank">
              <i className="icon-base icon-youtube"></i>
            </a>
            <Contact />
          </div>          
        </div>
      </nav>
    );
  }
}

export default MainMenu;
