import React from "react";
import Modal from "../../common/modal/modal";
import { Form, Field } from "react-final-form";
import Api from "../../common/api/api";

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      showModal: false,
      isHidden: true,
    };
  }
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      isHidden: true,
    });
  };
  onSubmit = (values) => {
    console.log("?");
  };
  forgetPass = () => {
    this.setState({
      isHidden: false,
    });
  };

  render() {
    const modalChange = () => {
      this.setState({
        showModal: !this.state.showModal,
        isHidden: true,
      });
    };
    const formSubmit = (values) => {
      localStorage.removeItem("user");
      window.location.reload(false);
    };

    const lang = localStorage.getItem("lang");

    const getButton = (element) => {
      if (lang == "en") {
        return "Logout";
      } else {
        return "Çıkış Yap";
      }
    };

    const { showModal } = this.state;
    return (
      <div>
        <button className="general-btn" onClick={this.toggleModal}>
          <i className="icon-base icon-login"></i>
        </button>
        {showModal ? (
          <a className="logout-btn" onClick={formSubmit}>
            {getButton()}
          </a>
        ) : null}
      </div>
    );
  }
}
export default Logout;
