import React from "react";
import { Form, Field } from "react-final-form";
import Api from "../../common/api/api";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const lang = localStorage.getItem("lang");

    const getButton = (element) => {
      if (lang == "en") {
        return "Change Password";
      } else {
        return "Şifremi Değiştir";
      }
    };

    const resetPassword = (values) => {
      const users = JSON.parse(localStorage.getItem("user")) || [];
      var payload = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        newPasswordRepeat: values.newPasswordRepeat,
        Email: users.Email,
        ID: users.ID,
      };
      Api.post("home/updatePassword", payload)
        .then(function (response) {
          if (response.data.status == "success") {
            alert("Başarıyla güncellendi");
            window.location.reload(false);
          } else {
            alert("Kullanıcı adı yada şifre yanlış");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    return (
      <div>
        <Form
          onSubmit={resetPassword}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  name="oldPassword"
                  component="input"
                  type="password"
                  placeholder="Eski Şifre"
                />
              </div>
              <div>
                <Field
                  name="newPassword"
                  component="input"
                  type="password"
                  placeholder="Yeni Şifre"
                />
              </div>
              <div>
                <Field
                  name="newPasswordRepeat"
                  component="input"
                  type="password"
                  placeholder="Yeni Şifre Tekrar"
                />
              </div>
              <div className="buttons">
                <button
                  type="button"
                  className="btn-form-action"
                  onClick={form.submit}
                >
                  {getButton()}
                </button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}
export default ChangePassword;
