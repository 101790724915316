import React from "react";
import { Form, Field } from "react-final-form";
import Api from "../../common/api/api";
import { Link } from "@reach/router";

class MessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: "",
      enabled: false,
    };
  }

  handleChange = (e) => {
    this.setState({ selectValue: e.target.value });
  };

  handleContactType = (e) => {
    this.setState({ contactType: e.target.value });
  };

  handleCheck = (e) => {
    this.setState({ enabled: !this.state.enabled });
    e.preventDefault;
  };

  isChecked = (e) => {
    return this.state.enabled;
  };

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  render() {
    const lang = localStorage.getItem("lang");

    const formSubmit = (values) => {
      let users = JSON.parse(localStorage.getItem("user")) || [];
      if (users.length != 0) {
        if (!values.firstName) {
          alert("Lütfen ad alanını doğru şekilde doldurunuz");
        } else if (!this.state.enabled) {
          alert("Lütfen Aydınlatma metnini kabul ediniz");
        } else if (!values.lastName) {
          alert("Lütfen soyad alanını doğru şekilde doldurunuz");
        } else if (!values.email) {
          alert("Lütfen email alanını doğru şekilde doldurunuz");
        } else if (!this.state.contactType || this.state.contactType == 0) {
          alert("Lütfen İletişim Tercihi seçiniz");
        } else if (!values.phone) {
          alert("Lütfen telefon alanını doğru şekilde doldurunuz");
        } else if (!this.state.selectValue) {
          alert("Lütfen alanları doğru şekilde doldurunuz");
        } else if (!values.notes) {
          alert("Lütfen mesaj alanını şekilde doldurunuz");
        } else {
          var payload = {
            FirstName: values.firstName,
            LastName: values.lastName,
            Email: values.email,
            DocGuid: this.state.selectValue,
            MobilePhone: values.phone,
            Model: values.notes,
            Lang: lang,
            ContactType: this.state.contactType,
          };
          Api.post("home/sendMail", payload)
            .then(function (response) {
              if (response.data.status == "success") {
                alert("Başarıyla Gönderildi");
              } else {
                alert("Lütfen alanları doğru şekilde doldurunuz");
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        if (!values.firstName) {
          alert("Lütfen ad alanını doğru şekilde doldurunuz");
        } else if (!values.lastName) {
          alert("Lütfen soyad alanını doğru şekilde doldurunuz");
        } else if (!values.email) {
          alert("Lütfen email alanını doğru şekilde doldurunuz");
        } else if (!this.state.contactType || this.state.contactType == 0) {
          alert("Lütfen İletişim Tercihi seçiniz");
        } else if (!values.phone) {
          alert("Lütfen telefon alanını doğru şekilde doldurunuz");
        } else if (!this.state.enabled) {
          alert("Lütfen Aydınlatma metnini kabul ediniz");
        } else if (!values.notes) {
          alert("Lütfen mesaj alanını şekilde doldurunuz");
        } else {
          var payload = {
            FirstName: values.firstName,
            LastName: values.lastName,
            Email: values.email,
            MobilePhone: values.phone,
            Model: values.notes,
            Lang: lang,
            ContactType: this.state.contactType,
          };
          Api.post("home/sendMail", payload)
            .then(function (response) {
              if (response.data.status == "success") {
                alert("Başarıyla Gönderildi");
              } else {
                alert("Lütfen alanları doğru şekilde doldurunuz");
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    };

    const gotoKvvkk = () => {
      if (lang == "en") {
        window.open("/about-us/kvkk-en", "_blank");
      } else {
        window.open("/hakkimizda/kvkk", "_blank");
      }
    };

    const getdropdown = (users) => {
      if (users.length != 0) {
        if (users.FK_MemberRolesID == 1) {
          if (lang == "en") {
            return (
              <div>
                <select
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option value="0">Select Subject</option>
                  <option value="99">
                    {" "}
                    ☎️ I want to contact top management immediately
                  </option>
                  <option value="1">I want to add new members</option>
                  <option value="2">
                    I’d like to send suggestions about YASED Events
                  </option>
                  <option value="3">
                    I want to make suggestions for the YASED agenda
                  </option>
                  <option value="4">I want to contact another member</option>
                  <option value="5">
                    I want to share a legislation proposal
                  </option>
                  <option value="6">Other</option>
                </select>
              </div>
            );
          } else {
            return (
              <div>
                <select
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option value="0">Konu Seçiniz</option>
                  <option value="99">
                    ☎️ Üst yönetimle hemen iletişime geçmek istiyorum
                  </option>
                  <option value="1">
                    YASED’e yeni üye kazandırmak istiyorum
                  </option>
                  <option value="2">
                    {" "}
                    YASED Etkinlikleri için öneri iletmek istiyorum
                  </option>
                  <option value="3">
                    YASED Gündemi için öneri getirmek istiyorum
                  </option>
                  <option value="4">
                    Diğer bir üyeyle iletişime geçmek istiyorum
                  </option>
                  <option value="5">Mevzuat önerisi iletmek istiyorum</option>
                  <option value="6">Diğer</option>
                </select>
              </div>
            );
          }
        } else {
          if (lang == "en") {
            return (
              <div>
                <select
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option value="0">Select Subject</option>
                  <option value="1">I want to add new members</option>
                  <option value="2">
                    I’d like to send suggestions about YASED Events
                  </option>
                  <option value="3">
                    I want to make suggestions for the YASED agenda
                  </option>
                  <option value="4">I want to contact another member</option>
                  <option value="5">
                    I want to share a legislation proposal
                  </option>
                  <option value="6">Other</option>
                </select>
              </div>
            );
          } else {
            return (
              <div>
                <select
                  value={this.state.selectValue}
                  onChange={this.handleChange}
                >
                  <option value="0">Konu Seçiniz</option>
                  <option value="1">
                    YASED’e yeni üye kazandırmak istiyorum
                  </option>
                  <option value="2">
                    {" "}
                    YASED Etkinlikleri için öneri iletmek istiyorum
                  </option>
                  <option value="3">
                    YASED Gündemi için öneri getirmek istiyorum
                  </option>
                  <option value="4">
                    Diğer bir üyeyle iletişime geçmek istiyorum
                  </option>
                  <option value="5">Mevzuat önerisi iletmek istiyorum</option>
                  <option value="6">Diğer</option>
                </select>
              </div>
            );
          }
        }
      }
    };
    const getcontactType = () => {
      if (lang == "en") {
        return (
          <div>
            <select
              value={this.state.contactType}
              onChange={this.handleContactType}
            >
              <option value="0">Communication preference</option>
              <option value="1"> Via phone/sms</option>
              <option value="2"> Via e-mail</option>
            </select>
          </div>
        );
      } else {
        return (
          <div>
            <select
              value={this.state.contactType}
              onChange={this.handleContactType}
            >
              <option value="0">İletişim tercihiniz</option>
              <option value="1"> Telefon/SMS</option>
              <option value="2"> e-posta</option>
            </select>
          </div>
        );
      }
    };
    const getCheckbox = () => {
      if (lang == "en") {
        return (
          <div className="text-left relative">
            <div
              className="check-absolute"
              onClick={(e) => this.handleCheck(!this.state.enabled)}
            ></div>
            <Field
              type="checkbox"
              component="input"
              checked={this.state.enabled}
              value={this.state.enabled}
              onClick={(e) => {
                console.log("asda");
                this.setState({ enabled: !this.state.enabled });
              }}
              name="checkbox"
            />
            &nbsp;I've read and accepted the
            <Link
              to="/about-us/kvkk-en"
              onClick={gotoKvvkk}
              className="text-black"
            >
              Clarification Text.
            </Link>
          </div>
        );
      } else {
        return (
          <div className="text-left relative">
            <div
              className="check-absolute"
              onClick={(e) => this.handleCheck(!this.state.enabled)}
            ></div>
            <Field
              type="checkbox"
              component="input"
              checked={this.state.enabled}
              value={this.state.enabled}
              onClick={(e) => {
                console.log("asda");
                this.setState({ enabled: !this.state.enabled });
              }}
              name="checkbox"
            />
            <Link
              to="/hakkimizda/kvkk"
              onClick={gotoKvvkk}
              className="text-black"
            >
              Aydınlatma metnini
            </Link>
            okudum, kabul ediyorum.
          </div>
        );
      }
    };

    const getContent = (element) => {
      const users = JSON.parse(localStorage.getItem("user")) || [];
      if (lang == "en") {
        return (
          <Form
            onSubmit={formSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <div>
                <div>
                  <Field
                    name="firstName"
                    component="input"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div>
                  <Field
                    name="lastName"
                    component="input"
                    type="text"
                    placeholder="Surname"
                  />
                </div>
                <div>
                  <Field
                    name="email"
                    component="input"
                    type="text"
                    placeholder="E-mail address"
                  />
                </div>
                {getcontactType()}
                {getdropdown(users)}
                <div>
                  <Field
                    name="phone"
                    component="input"
                    type="text"
                    placeholder="Phone"
                  />
                </div>
                <div>
                  <Field
                    name="notes"
                    component="textarea"
                    placeholder="Message"
                  />
                </div>

                {getCheckbox()}

                <div className="buttons">
                  <button
                    className="btn-form-action"
                    type="submit"
                    onClick={form.submit}
                  >
                    Send
                  </button>
                </div>
              </div>
            )}
          />
        );
      } else {
        return (
          <Form
            onSubmit={formSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <div>
                <div>
                  <Field
                    name="firstName"
                    component="input"
                    type="text"
                    placeholder="Adınız"
                  />
                </div>

                <div>
                  <Field
                    name="lastName"
                    component="input"
                    type="text"
                    placeholder="Soyadınız"
                  />
                </div>
                <div>
                  <Field
                    name="email"
                    component="input"
                    type="text"
                    placeholder="E-posta adresiniz"
                  />
                </div>
                {getcontactType()}
                {getdropdown(users)}
                <div>
                  <Field
                    name="phone"
                    component="input"
                    type="text"
                    placeholder="Cep telefonunuz"
                  />
                </div>
                <div>
                  <Field
                    name="notes"
                    component="textarea"
                    placeholder="Mesajınız"
                  />
                </div>
                {getCheckbox()}
                <div className="buttons">
                  <button
                    className="btn-form-action"
                    type="submit"
                    onClick={form.submit}
                  >
                    Gönder
                  </button>
                </div>
              </div>
            )}
          />
        );
      }
    };

    return getContent();
  }
}
export default MessageForm;
