import React from "react";
import Slider from "react-slick";
import Api from "../../common/api/api";
import MemberItem from "./memberItem";

class MemberYased extends React.Component {
  constructor() {
    super();
    this.state = {
      data: [],
    };
    this.TABS = [
      {
        label: "W-Z",
        value: 8,
        tabOrderVal: "",
      },{
        label: "T-V",
        value: 7,
        tabOrderVal: "",
      },{
        label: "P-S",
        value: 6,
        tabOrderVal: "",
      },{
        label: "L-Ö",
        value: 5,
        tabOrderVal: "",
      },{
        label: "I-K",
        value: 4,
        tabOrderVal: "",
      },{
        label: "E-H",
        value: 3,
        tabOrderVal: "",
      },{
        label: "A-D",
        value: 2,
        tabOrderVal: "",
      },{
        label: "0-9",
        value: 1,
      }
    ]
  }

  componentDidMount() {
    Api.get("/members/getlist/").then((res) => {
      this.setState({ data: res.data ,activeItem: res.data });
    });
  }

  activeTab = (actVal) => {
   
    let items = this.state.data;
    var activeItem = [];
    items.map((val, index) => {
      var cat = ["0","1","2","3","4","5","6","7","8","9"]
      if(actVal == 2){
        cat = ["A","B","C","D"];
      }else if(actVal == 3){
        cat = ["E","F","G","H"];
      }else if(actVal == 4){
        cat = ["I","İ","J","K"];
      }else if(actVal == 5){
        cat = ["L","M","N","O","Ö"];
      }else if(actVal == 6){
        cat = ["P","Q","R","S","Ş"];
      }else if(actVal == 7){
        cat = ["T","U","Ü","V"];
      }else if(actVal == 8){
        cat = ["Y","Z","W","X"];
      }
      if (cat.includes(val.Title.charAt(0).toString())) {
        activeItem.push(val);
      }
    });
    this.setState({ tabsRefill: true,activeItem: activeItem,activeTab: actVal});
  };

  render() {
    const data = this.state.data;
    const lang = localStorage.getItem('lang');
    const { activeItem, tabsRefill, activeTab } = this.state;
    const getTitle = (element) => {
      if (lang == "en"){
        return (
          <h1 className="about-title">Members</h1>
        )
      }else{
        return (
          <h1 className="about-title">Üyelerimiz</h1>
        ) 
      }
    }

    return (
      <div className="about-container">
        {getTitle()}
        <div className="member-box">
          {data.length && (
            <>
              <div className="main-tab">
                {this.TABS.map(({ label, value }) => (
                  <div key={value}>
                    <div
                      className={`tab ${
                        value === activeTab ? "selected-tab" : ""
                      }`}
                      onClick={() => {
                        this.activeTab(value);
                      }}
                      key={`${value}key`}
                    >
                        <div className="main-tab-label">
                         
                          <span>{label}</span>
                        </div>
                    </div>
                  </div>
                ))}
             </div>
            {activeItem.length && <MemberItem item={activeItem} />}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default MemberYased;
