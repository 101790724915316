import React from "react";
import AcademyItem from "./academyItem";
import Slider from "react-slick";
import moment from "moment";
import Api from "../../common/api/api";

class Academy extends React.Component {
  constructor(props) {
    super(props);

    const lang = localStorage.getItem("lang");
    this.state = {
      activeTab: 1,
      activeItem: [],
      data: "",
      tabsRefill: false,
    };
  }

  componentDidMount() {
    let users = JSON.parse(localStorage.getItem("user")) || [];
    if (users.length != 0) {
      Api.get("/news/getlist/6?login=" + users.Email).then((res) => {
        this.setState({
          data: res.data,
          activeItem: res.data.items,
        });
      });
    } else {
      Api.get("/news/getlist/6").then((res) => {
        this.setState({
          data: res.data,
          activeItem: res.data.items,
        });
      });
    }
  }

  render() {
    const { activeItem } = this.state;

    return (
      <div className="news-cover">
        <div class="main-tab"></div>
        <div className="main-tab-content">
          {activeItem.length && <AcademyItem item={activeItem} />}
        </div>
      </div>
    );
  }
}
export default Academy;
