import React from "react";
import Slider from "react-slick";
import ReportItem from "./reportItem";
import Api from "../../common/api/api";

class Reports extends React.Component {
  constructor(props) {
    super(props);

    const lang = localStorage.getItem('lang');

    if (lang == "en"){

    this.TABS = [
      {
        label: "All",
        value: 1,
        items: [
          {
            img: "http://placecorgi.com/200/200",
            title: "1 YASED Faaliyet Raporu",
            file: "file",
            link: "insight/1",
          }
        ],
      }
    ];
  }else{
    this.TABS = [
      {
        label: "Hepsi",
        value: 1,
        items: [
          {
            img: "http://placecorgi.com/200/200",
            title: "1 YASED Faaliyet Raporu",
            file: "file",
            link: "insight/1",
          }
        ],
      }
    ];
  }
    this.state = {
      activeTab: 1,
      activeItem: [],
    };
  }


  
  componentDidMount() {
    Api.get("/reports/getlistbycat/").then((res) => {
      this.fillTabs(res.data.data ,res.data.notes);
    });
  }


  fillTabs = (data,notes) => {
    const lang = localStorage.getItem('lang');

    let items = data;
    items.map((val, index) => {
    if (lang == "en"){
      if(val.Name_EN != null){
        this.TABS.push({
            label: val.Name_EN,
            value: 2 + index,
            tabOrderVal: val.Id,
          });
        }
      }else{
        this.TABS.push({
          label: val.Name,
          value: 2 + index,
          tabOrderVal: val.Id,
        });
      }
    });

    this.setState({ tabsRefill: true });
    this.setState({ activeItem: notes });
    this.setState({ data: notes });
  };


  activeTab = (actVal) => {
    let tabOrder = this.TABS.filter((tab) => tab.value === actVal),
      filteredItems = [];
    if (actVal === 1) {
      filteredItems = this.state.data;
    } else {
      filteredItems = this.state.data.filter((item) => {
        if (item.ParentId === tabOrder[0].tabOrderVal) {
          return item;
        }
      });
    }
    this.setState({
      activeTab: actVal,
      activeItem: filteredItems,
    });
  };

  render() {
    const { activeItem, tabsRefill, activeTab } = this.state;
    const settings = {
      className: "slider variable-width",
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
    };
    
    return (
      <div className="report-cover">
        <div className="main-tab">
        {tabsRefill && (
          <Slider {...settings}>
            {this.TABS.map(({ label, value }) => (
              <div key={value}>
                <div
                  className={`tab ${
                    value === activeTab ? "selected-tab" : ""
                  }`}
                  onClick={() => {
                    this.activeTab(value);
                  }}
                  key={`${value}key`}
                >
                  {label === "Hepsi" ? (
                      <div className="main-tab-label">
                        <span>
                          <i className="icon-base icon-calendar-all"></i>
                        </span>
                        <span>{label}</span>
                      </div>
                    ) : (
                      <div className="main-tab-label">
                        <span>
                          <i className="icon-base icon-calendar"></i>
                        </span>
                        <span>{label}</span>
                      </div>
                    )}
                </div>
              </div>
            ))}
          </Slider>
          )}
        </div>
        <div className="main-tab-content">
          {activeItem.length &&<ReportItem item={this.state.activeItem} />}
        </div>
      </div>
    );
  }
}

export default Reports;
