import React from "react";
import Modal from "../../common/modal/modal";
import MessageForm from "./messageForm";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }
  toggleModal = () => this.setState({ showModal: !this.state.showModal });
  
  render() {
    const lang = localStorage.getItem('lang');
    const getContent = (element) => {
      if (lang == "en"){
        return (
          <div className="contact-right">
          <div className="contact-block">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6016.937435605218!2d29.007829999999995!3d41.058746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7ace35bdc41%3A0x1ddedaf9fcc4c811!2zRGlraWxpdGHFnywgRW1pcmhhbiBDZC4gTm86MTEzLCAzNDM0OSBCZcWfaWt0YcWfL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1600199265737!5m2!1str!2str"
                width="100%"
                height="150"
                style={{ border: 0 }}
                aria-hidden="false"
              ></iframe>
            </div>
            <h2 className="contact-title">YASED İstanbul</h2>
            <p className="contact-address">
              Dikilitaş Emirhan Cad. No. 113 Barbaros Plaza Kat 16 34349
              Beşiktaş / İstanbul
              <span>
                Phone: 0212 272 50 94 <br />
                Fax: 0212 274 66 64
              </span>
              <span className="contact-mail">info@yased.org.tr</span>
            </p>
          </div>
          <div className="contact-block contact-block-top">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d321.6901561816015!2d32.75538793226961!3d39.90894964689205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbc793f484bf87602!2zMznCsDU0JzMyLjkiTiAzMsKwNDUnMTkuMCJF!5e0!3m2!1str!2str!4v1647345628301!5m2!1str!2str"
                width="100%"
                height="150"
                style={{ border: 0 }}
                aria-hidden="false"
              ></iframe>
            </div>
            <h2 className="contact-title">YASED Ankara</h2>
            <p className="contact-address">
              Tepe Prime İş ve Yaşam Merkezi, Mustafa Kemal Mah. Dumlupınar Blv. 
              No: 266, A Blok Asma Kat No: 21-22, 06800 Çankaya / Ankara 
              <span>
                Phone: 0312 219 42 21 <br />
                Fax: 0212 274 66 64
              </span>
            </p>
          </div>
        </div>
        )
      }else{
        return (
          <div className="contact-right">
          <div className="contact-block">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6016.937435605218!2d29.007829999999995!3d41.058746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7ace35bdc41%3A0x1ddedaf9fcc4c811!2zRGlraWxpdGHFnywgRW1pcmhhbiBDZC4gTm86MTEzLCAzNDM0OSBCZcWfaWt0YcWfL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1600199265737!5m2!1str!2str"
                width="100%"
                height="150"
                style={{ border: 0 }}
                aria-hidden="false"
              ></iframe>
            </div>
            <h2 className="contact-title">YASED İstanbul</h2>
            <p className="contact-address">
              Dikilitaş Emirhan Cad. No. 113 Barbaros Plaza Kat 16 34349
              Beşiktaş / İstanbul
              <span>
                Tel: 0212 272 50 94 <br />
                Faks: 0212 274 66 64
              </span>
              <span className="contact-mail">info@yased.org.tr</span>
            </p>
          </div>
          <div className="contact-block contact-block-top">
            <div className="contact-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d321.6901561816015!2d32.75538793226961!3d39.90894964689205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbc793f484bf87602!2zMznCsDU0JzMyLjkiTiAzMsKwNDUnMTkuMCJF!5e0!3m2!1str!2str!4v1647345628301!5m2!1str!2str"
                width="100%"
                height="150"
                style={{ border: 0 }}
                aria-hidden="false"
              ></iframe>
            </div>
            <h2 className="contact-title">YASED Ankara</h2>
            <p className="contact-address">
              Tepe Prime İş ve Yaşam Merkezi, Mustafa Kemal Mah. Dumlupınar Blv. 
              No: 266, A Blok Asma Kat No: 21-22, 06800 Çankaya / Ankara 
              <span>
                Phone: 0312 219 42 21 <br />
                Fax: 0212 274 66 64
              </span>
            </p>
          </div>
        </div>
        )
      }
    }


    const { showModal } = this.state;
    return (
      <div className="contact-module">
        <button className="general-btn" onClick={this.toggleModal}>
          <i className="icon-base icon-connect"></i>
          <span className="footer-connect-text">YASED CONNECT</span>
        </button>
        {showModal ? (
          <Modal>
            <div className="contact-modal">
              <button
                className="close-modal"
                onClick={this.toggleModal}
              ></button>
              <div className="contact-left">
                <MessageForm />
              </div>
              {getContent()}
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
export default Contact;
