import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Api from "../../common/api/api";
import moment from "moment";

class EventCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: new Date(),
      showModal: false,
      data: []
    };
  }

  componentDidMount(){
    var today = new Date(this.props.value);
    var dd = String(today. getDate()). padStart(2, '0');
    var mm = String(today. getMonth() + 1). padStart(2, '0');
    var yyyy = today. getFullYear();
    Api.get("activities/getdetail?slug="+dd+"."+mm+"."+yyyy).then((res) => {
      this.setdata(res.data);
    });

    Api.get("activities/getActivities").then((res) => {
      let date = []
      res.data.forEach(element => {
        date.push(moment(element.Date).format('DD-MM-YYYY'));
      });
      this.setState({data: date});
    });

  }
  
  onClick = (value, event) => {
    var today = new Date(value);
    var dd = String(today. getDate()). padStart(2, '0');
    var mm = String(today. getMonth() + 1). padStart(2, '0');
    var yyyy = today. getFullYear();
    Api.get("activities/getdetail?slug="+dd+"."+mm+"."+yyyy).then((res) => {
      this.setdata(res.data);
      console.log(res.data);
    });
    this.setState({ value });
  };

  setdata (data){
    this.setState({ content: data });
  }

  render() {
    const { value } = this.state;
    let title;
    let content;
    let date;
    let image;
    if (this.state.content) {
      date = moment(this.state.content.Date).format('DD.MM.YYYY');
      const lang = localStorage.getItem('lang');
      if (lang == "en"){
        title = this.state.content.Title_EN;
        content = this.state.content.Content_EN;
      }else{
        title = this.state.content.Title;
        content = this.state.content.Content;
      }

      if (this.state.content.Image != null){
        image = this.state.content.Image;
      }
    }

    const getLang = () => {
      const lang = localStorage.getItem('lang');
      if (lang == "en"){
        return "en-EN"
      }else{
        return "tr-TR"
      }
    }

    return (
      <div className="calendar-box">
        <div className="calendar-detail">
          <h4 className="cal-event-date">{date}</h4>
          <div className="cal-event-text">
            {image ? (
            <img
              src={"https://yased-api.yased.org.tr/" + image} width="100%"
            />
            ):null}
            <h5 className="cal-event-text-title">{title}</h5>
            <Scrollbars autoHide={false} autoHeight autoHeightMax={`40vh`}>
              <div 
               dangerouslySetInnerHTML={{
                __html: content,
              }}></div> 
            </Scrollbars>
          </div>
        </div>
        <div className="calendar-app">
          <Calendar
            onClickDay={this.onClick}
            locale={getLang()}
            style={{ height: "59vh" }}
            tileClassName={({ date, view }) => {
              if(this.state.data.find(x=>x===moment(date).format("DD-MM-YYYY"))){
              return  'highlight'
              }
            }}
          />
        </div>
      </div>
    );
  }
}
export default EventCalendar;
