import React from "react";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars";
import Api from "../../common/api/api";

class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: [],
    };
  }

  componentDidMount() {
    Api.get("news/getdetail?slug=" + this.props.id).then((res) => {
      const lang = localStorage.getItem("lang");
      if (lang == "en") {
        if (res.data.news.Title_EN == null) {
          window.location.href = "/";
        }
      }
      //if isnot login and response is only login view redirect
      let users = JSON.parse(localStorage.getItem("user")) || [];
      if (res.data.news.Is_Login == 1 && users.length == 0) {
        window.location.href = "/";
      }
      this.setState({ content: res.data.news });
    });
  }

  render() {
    const lang = localStorage.getItem("lang");

    const getTitle = (element) => {
      if (lang == "en") {
        return <h2 className="insight-title">{element.Title_EN}</h2>;
      } else {
        return <h2 className="insight-title">{element.Title}</h2>;
      }
    };

    const getSpot = (element) => {
      if (lang == "en") {
        return (
          <div
            className="insight-slider-text"
            dangerouslySetInnerHTML={{ __html: this.state.content.Spot_EN }}
          ></div>
        );
      } else {
        return (
          <div
            className="insight-slider-text"
            dangerouslySetInnerHTML={{ __html: this.state.content.Spot }}
          ></div>
        );
      }
    };

    const getContent = (element) => {
      if (lang == "en") {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.content.Content_EN,
            }}
          ></div>
        );
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.content.Content,
            }}
          ></div>
        );
      }
    };

    const getNote = () => {
      if (lang == "en") {
        return (
          <span className="insight-slider-down-text">Click Meeting note</span>
        );
      } else {
        return (
          <span className="insight-slider-down-text">
            Toplantı notu için tıklayın
          </span>
        );
      }
    };

    const getVideo = () => {
      if (lang == "en") {
        return (
          <div className="insight-slider-action">
            {this.state.content.Video_EN ? (
              <a href={this.state.content.Video_EN} target="_blank">
                <span className="insight-slider-play"></span>
              </a>
            ) : null}
            {this.state.content.Docs ? (
              <a
                href={
                  "https://yased-api.yased.org.tr/" + this.state.content.Docs
                }
                target="_blank"
              >
                <i className="icon-base icon-pdf"></i>
                {getNote()}
              </a>
            ) : null}
          </div>
        );
      } else {
        return (
          <div className="insight-slider-action">
            {this.state.content.Video ? (
              <a href={this.state.content.Video} target="_blank">
                <span className="insight-slider-play"></span>
              </a>
            ) : null}
            {this.state.content.Docs ? (
              <a
                href={
                  "https://yased-api.yased.org.tr/" + this.state.content.Docs
                }
                target="_blank"
              >
                <i className="icon-base icon-pdf"></i>
                {getNote()}
              </a>
            ) : null}
          </div>
        );
      }
    };

    const settings = {
      slidesToShow: 1,
      speed: 500,
      slidesToScroll: 1,
      infinite: false,
    };

    return (
      <div className="fullpage-container">
        <div className="insight-top">
          <div className="insight-image">
            <img
              src={"https://yased-api.yased.org.tr/" + this.state.content.Image}
            />
          </div>
          <div className="insight-slider-box">
            <Slider {...settings}>
              <div>
                <div className="insight-slider-hold">
                  {getTitle(this.state.content)}
                  {getSpot(this.state.content)}
                  {getVideo(this.state.content)}
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="insight-text-container">
          <Scrollbars autoHide={false} autoHeight autoHeightMax={`30vh`}>
            <div className="insight-text">{getContent(this.state.content)}</div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default Detail;
