import React from "react";
import Modal from "../../common/modal/modal";
import ProfileUpdate from "./profileUpdate";
import ChangePassword from "./changePassword";

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }
  toggleModal = () => this.setState({ showModal: !this.state.showModal });
  onSubmit = (values) => {
    console.log("?");
  };
  render() {
    const lang = localStorage.getItem('lang');

    const getProfileTitle = (element) => {
      if (lang == "en"){
        return (
          <h2 className="setting-modal-title">Profile Settings</h2>
        )
      }else{
        return (
          <h2 className="setting-modal-title">Profil Ayarları</h2>
        ) 
      }
    }

    const getPasswordTitle = (element) => {
      if (lang == "en"){
        return (
          <h2 className="setting-modal-title">Password Settings</h2>
        )
      }else{
        return (
          <h2 className="setting-modal-title">Şifre Ayarları</h2>
        ) 
      }
    }

    const { showModal } = this.state;
    return (
      <div>
        <button className="general-btn" onClick={this.toggleModal}>
          <i className="icon-base icon-setting"></i>
        </button>
        {showModal ? (
          <Modal>
            <div className="setting-modal">
              <button
                className="close-modal"
                onClick={this.toggleModal}
              ></button>
              {getProfileTitle()}
              <ProfileUpdate />
              {getPasswordTitle()}
              <ChangePassword />
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
export default ProfileSettings;
