import React from "react";
import { render } from "react-dom";
import { Router } from "@reach/router";
// import { HashRouter, Route } from "react-router-dom";
import Home from "./pages/homepage/home";
import Header from "./pages/header/header";
import MainMenu from "./pages/menus/mainMenu";
import Footer from "./pages/footer/footer";
import Reports from "./pages/report/report";
import News from "./pages/news/news";
import NewsDetail from "./pages/news/newsDetail";
import Reflection from "./pages/reflection/reflection";
import ReflectionDetail from "./pages/reflection/reflectionDetail";
import Academy from "./pages/academy/academy";
import AcademyDetail from "./pages/academy/academyDetail";
import UserNews from "./pages/usernews/usernews";
import UserNewsDetail from "./pages/usernews/usernewsDetail";
import Works from "./pages/yasedworks/works";
import WorksDetail from "./pages/yasedworks/detail";
import International from "./pages/international/list";
import InternationalDetail from "./pages/international/detail";
import United from "./pages/united/united";
import Search from "./pages/search/search";
import UnitedDetail from "./pages/united/unitedDetail";
import Insight from "./pages/insight/insight";
import InsightList from "./pages/insight/insightlist";
import About from "./pages/about/about";
import AboutYased from "./pages/about/aboutYased";
import WorkGroup from "./pages/workGroup/workGroup";
import GroupContent from "./pages/workGroup/groupContent";

import Digital from "./pages/digital/digital";
import DigitalDetail from "./pages/digital/digitalDetail";
import DigitalNews from "./pages/digital/news/news";
import DigitalNewsDetail from "./pages/digital/news/newsDetail";

import Sustain from "./pages/sustain/sustain";
import SustainDetail from "./pages/sustain/sustainDetail";
import SustainNews from "./pages/sustain/news/news";
import SustainNewsDetail from "./pages/sustain/news/newsDetail";

import Chain from "./pages/chain/chain";
import ChainDetail from "./pages/chain/chainDetail";
import ChainNews from "./pages/chain/news/news";
import ChainNewsDetail from "./pages/chain/news/newsDetail";

import HumanCapital from "./pages/humanCapital/humanCapital";
import HumanCapitalDetail from "./pages/humanCapital/humanCapitalDetail";
import HumanCapitalNews from "./pages/humanCapital/news/news";
import HumanCapitalNewsDetail from "./pages/humanCapital/news/newsDetail";

import "../src/styles/main.scss";

const App = () => {
  return (
    <div>
      <div className="main-box">
        <Header />
        <MainMenu />
        <div className="content-area">
          <Router>
            <Home path="/" />
            <Reports path="raporlar" />

            <News path="haberler" />
            <NewsDetail path="haberler/:id" />

            <Reflection path="basin-yansimalari" />
            <ReflectionDetail path="basin-yansimalari/:id" />

            <Academy path="yased-akademi" />
            <AcademyDetail path="yased-akademi/:id" />

            <UserNews path="uyelerimizden-haberler" />
            <UserNewsDetail path="uyelerimizden-haberler/:id" />

            <Works path="yased-calisiyor" />
            <WorksDetail path="yased-calisiyor/:id" />

            <International path="yatirim-zirvesi" />
            <InternationalDetail path="yatirim-zirvesi/:id" />

            <Search path="search" />

            <United path="united" />
            <UnitedDetail path="united/:id" />

            <InsightList path="insight/" />
            <Insight path="insight/:id" />

            {/* new updates */}

            <Digital path="dijital-donusum">
              <DigitalDetail path={"/:id"} />
            </Digital>

            <DigitalNews path="dijital-donusum/haberler" />
            <DigitalNewsDetail path="dijital-donusum/haberler/:id" />

            <Sustain path="surdurulebilir-kalkinma">
              <SustainDetail path={"/:id"} />
            </Sustain>

            <SustainNews path="surdurulebilir-kalkinma/haberler" />
            <SustainNewsDetail path="surdurulebilir-kalkinma/haberler/:id" />

            <Chain path="kuresel-tedarik-zinciri">
              <ChainDetail path={"/:id"} />
            </Chain>

            <ChainNews path="kuresel-tedarik-zinciri/haberler" />
            <ChainNewsDetail path="kuresel-tedarik-zinciri/haberler/:id" />

            <HumanCapital path="insan-sermayesinin-gelistirilmesi">
              <HumanCapitalDetail path={"/:id"} />
            </HumanCapital>

            <HumanCapitalNews path="insan-sermayesinin-gelistirilmesi/haberler" />
            <HumanCapitalNewsDetail path="insan-sermayesinin-gelistirilmesi/haberler/:id" />

            {/* new updates */}

            <About path="hakkimizda">
              <AboutYased path={"/:id"} />
            </About>

            <WorkGroup path="/workGroup">
              <GroupContent path={"/:id"} />
            </WorkGroup>

            {/* for en */}

            <About path="about-us">
              <AboutYased path={"/:id"} />
            </About>

            <Reports path="reports" />

            <Digital path="digitalization-platform">
              <DigitalDetail path={"/:id"} />
            </Digital>

            <DigitalNews path="digitalization-platform/news" />
            <DigitalNewsDetail path="digitalization-platform/news/:id" />

            <Sustain path="the-sustainable-development">
              <SustainDetail path={"/:id"} />
            </Sustain>

            <SustainNews path="the-sustainable-development/news" />
            <SustainNewsDetail path="the-sustainable-development/news/:id" />

            <Chain path="global-supply-chain-platform">
              <ChainDetail path={"/:id"} />
            </Chain>

            <ChainNews path="global-supply-chain-platform/news" />
            <ChainNewsDetail path="global-supply-chain-platform/news/:id" />

            <HumanCapital path="human-capital-development">
              <HumanCapitalDetail path={"/:id"} />
            </HumanCapital>

            <HumanCapitalNews path="human-capital-development/news" />
            <HumanCapitalNewsDetail path="human-capital-development/news/:id" />

            <News path="news" />
            <NewsDetail path="news/:id" />

            <Reflection path="news-reflection" />
            <ReflectionDetail path="news-reflection/:id" />

            <UserNews path="member-news" />
            <UserNewsDetail path="member-news/:id" />

            <Academy path="yased-academy" />
            <AcademyDetail path="yased-academy/:id" />

            <NotFound default />
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const NotFound = () => {
  window.location.href = "/";
};

render(<App />, document.getElementById("root"));
