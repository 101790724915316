import React from "react";
import Slider from "react-slick";

class ReportItem extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const lang = localStorage.getItem('lang');
    const ItemDetail = (image,title,file,i) => {
      return (
        <div  key={i}>
        <div className="report-item">
          <div className="report-item-img-box">
            <img  src={"https://yased-api.yased.org.tr" + image} className="report-item-img" alt="" />
          </div>
        <div className="report-item-content">
            <p className="report-item-title">{title}</p>
            <p className="report-item-action">
              <span className="report-item-action-button report-item-download">
                <a href={"https://yased-api.yased.org.tr" +file} download>
                  <i className="icon-base icon-download"></i>
                  <span className="report-item-action-text">DOWNLOAD</span>
                </a>
              </span>
              <span className="report-item-action-button report-item-show">
                <a href={"https://yased-api.yased.org.tr" +file}>
                  <i className="icon-base icon-magnify"></i>
                  <span className="report-item-action-text">
                    SHOW
                  </span>
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      )
    }

    const getItem = (item,i) => {
      if (lang == "en"){ 
        if(item.File_EN != null && item.Title_EN != null){
          return ( 
            ItemDetail(item.Image,item.Title_EN,item.File_EN,i)
          )
        }
      }else{
        if(item.File != null && item.Title != null){
          return ( 
            ItemDetail(item.Image,item.Title,item.File,i)
          )
        }
      }
    }

    const items = this.props.item;
    const settings = {
      slidesToShow: 4,
      speed: 500,
      rows: 4,
      slidesPerRow: 1,
      infinite: false,
      responsive: [
        {
          breakpoint: 1605,
          settings: {
            slidesToShow: 3,
            rows: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            rows: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>
          {items.map((item, key) => (
            getItem(item,key)
          ))}
        </Slider>
      </div>
    );
  }
}

export default ReportItem;
